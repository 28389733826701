/*
Navbar Area Style
======================================================*/
.navbar-area .navbar-brand {
  margin-right: 0;
  z-index: 3;
  /* padding: 15px 0; */
}

.navbar-area.is-sticky {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  position: fixed !important;
  background-color: #efefef !important;
  -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
          animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
}

.desktop-nav {
  padding-top: 0;
  padding-bottom: 0;
}

.desktop-nav .navbar {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: relative;
  padding-right: 0;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
}

.desktop-nav .navbar ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.desktop-nav .navbar .navbar-nav {
  z-index: 2;
  margin-left: auto;
  margin-right: 0;
}

.desktop-nav .navbar .navbar-nav .nav-item {
  position: relative;
  padding-bottom: 35px;
  padding-top: 35px;
  padding-right: 0;
  padding-left: 0;
}

.desktop-nav .navbar .navbar-nav .nav-item a {
  font-weight: 600;
  font-size: 14px;
  color: var(--heading-color);
  line-height: 1;
  position: relative;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 12px;
  margin-right: 12px;
}

.desktop-nav .navbar .navbar-nav .nav-item a:hover, .desktop-nav .navbar .navbar-nav .nav-item a:focus, .desktop-nav .navbar .navbar-nav .nav-item a.active {
  color: var(--main-color);
}

.desktop-nav .navbar .navbar-nav .nav-item a i {
  font-size: 18px;
  line-height: 0;
  position: relative;
  top: 4px;
}

.desktop-nav .navbar .navbar-nav .nav-item:last-child a {
  margin-right: 0;
}

.desktop-nav .navbar .navbar-nav .nav-item:first-child a {
  margin-left: 0;
}

.desktop-nav .navbar .navbar-nav .nav-item:hover a:before, .desktop-nav .navbar .navbar-nav .nav-item:focus a:before, .desktop-nav .navbar .navbar-nav .nav-item.active a:before {
  opacity: 1;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu {
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: var(--white-color);
  position: absolute;
  visibility: hidden;
  border-radius: 0;
  display: block;
  width: 250px;
  border: none;
  z-index: 99;
  opacity: 0;
  top: 83px;
  left: 0;
  padding: 0;
  pointer-events: none;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li {
  padding: 0;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a {
  color: var(--heading-color);
  padding: 13px;
  border-bottom: 1px dashed #eeeeee;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  display: block;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:before {
  display: none;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a i {
  float: right;
  top: 7px;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:hover, .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a:focus, .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li a.active {
  color: var(--main-color);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
  left: 250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
  color: var(--heading-color);
  border-bottom: 1px dashed #eeeeee;
  font-size: 13px;
  line-height: 1;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover, .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus, .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
  color: var(--main-color);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: 250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--black-color);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--main-color);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--black-color);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--main-color);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--black-color);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--main-color);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--black-color);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--main-color);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu {
  left: -250px;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a {
  color: var(--black-color);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:hover, .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a:focus, .desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li a.active {
  color: var(--main-color);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: var(--main-color);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: var(--main-color);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -1px;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: var(--main-color);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  top: -1px;
  visibility: visible;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li.active a {
  color: var(--main-color);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  top: -15px;
  visibility: visible;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li.active a {
  color: var(--main-color);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: -15px;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li.active a {
  color: var(--main-color);
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: 0;
}

.desktop-nav .navbar .navbar-nav .nav-item .dropdown-menu li:last-child a {
  border-bottom: none;
}

.desktop-nav .navbar .navbar-nav .nav-item:hover .dropdown-menu {
  top: 83px;
  opacity: 1;
  margin-top: 0;
  visibility: visible;
  pointer-events: auto;
}

.desktop-nav .navbar .others-options {
  position: relative;
  top: 0;
}

.desktop-nav .navbar .others-options ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  line-height: 1;
}

.desktop-nav .navbar .others-options ul li {
  display: inline-block;
  margin-right: 20px;
}

.desktop-nav .navbar .others-options ul li .search-icon {
  font-size: 25px;
  position: relative;
  top: 6px;
  background-color: transparent;
}

.desktop-nav .navbar .others-options ul li .social-link {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.desktop-nav .navbar .others-options ul li .social-link li {
  display: inline-block;
  margin-right: 10px;
}

.desktop-nav .navbar .others-options ul li .social-link li:last-child {
  margin-right: 0;
}

.desktop-nav .navbar .others-options ul li .social-link li a i {
  width: 25px;
  height: 25px;
  line-height: 25px;
  background-color: #bdbdbd;
  display: inline-block;
  border-radius: 50px;
  text-align: center;
  font-size: 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--white-color);
}

.desktop-nav .navbar .others-options ul li .social-link li a:hover i {
  background-color: var(--optional-color);
  color: var(--white-color);
}

.desktop-nav .navbar .others-options ul li:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}

.desktop-nav .navbar .others-options ul li:nth-child(4) {
  border-right: none;
}

.desktop-nav .navbar .others-options ul li .navbar-option-language {
  position: relative;
  top: 2px;
}

.desktop-nav .navbar .others-options ul li .navbar-option-language button {
  background-color: transparent;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 15px;
  font-weight: 500;
  color: var(--heading-color);
}

.desktop-nav .navbar .others-options ul li .navbar-option-language button img {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 35px;
          flex: 0 0 35px;
  max-width: 35px;
  border-radius: 50%;
  border: 3px solid var(--white-color);
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
  margin-right: 10px;
}

.desktop-nav .navbar .others-options ul li .navbar-option-language button i {
  font-size: 20px;
  margin-right: 10px;
  line-height: 1;
  color: var(--heading-color);
}

.desktop-nav .navbar .others-options ul li .navbar-option-language .dropdown-toggle:after {
  content: "\ea4e";
  font-family: 'remixicon' !important;
  font-size: 20px;
  margin-left: 5px;
  border: 0;
}

.desktop-nav .navbar .others-options ul li .navbar-option-language .dropdown-menu {
  margin: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
  display: block !important;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  right: 0;
  left: auto;
  border-radius: 0;
  padding: 8px 0;
  border: none;
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
  background-color: var(--white-color);
}

.desktop-nav .navbar .others-options ul li .navbar-option-language .dropdown-menu .dropdown-item {
  padding: 8px 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 15px;
}

.desktop-nav .navbar .others-options ul li .navbar-option-language .dropdown-menu .dropdown-item img {
  max-width: 25px;
  width: 25px;
  margin-right: 10px;
}

.desktop-nav .navbar .others-options ul li .navbar-option-language .dropdown-menu .dropdown-item:hover {
  color: var(--main-color);
  background-color: transparent;
}

.desktop-nav .navbar .others-options ul li .navbar-option-language .dropdown-menu .dropdown-item:focus {
  color: var(--main-color);
  background-color: transparent;
}

.desktop-nav .navbar .others-options ul li .navbar-option-language .dropdown-menu.show {
  -webkit-transform: translateY(26px);
          transform: translateY(26px);
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.mobile-responsive-nav {
  display: none;
}

/*
Others Option For Responsive Area Style
======================================================*/
.others-option-for-responsive {
  display: none;
}

.others-option-for-responsive .dot-menu {
  padding: 0 10px;
  height: 30px;
  cursor: pointer;
  z-index: 9991;
  position: absolute;
  right: 60px;
  top: -36px;
}

.others-option-for-responsive .dot-menu .inner {
  display: -webkit-box;
  display: -ms-flexbox;
  /* display: flex; */
  display: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 30px;
}

.others-option-for-responsive .dot-menu .inner .circle {
  height: 5px;
  width: 5px;
  border-radius: 100%;
  margin: 0 2px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: var(--main-color);
}

.others-option-for-responsive .dot-menu:hover .inner .circle {
  background-color: var(--main-color);
}

.others-option-for-responsive .container {
  position: relative;
}

.others-option-for-responsive .container .container {
  position: absolute;
  right: -2px;
  top: 10px;
  max-width: 320px;
  margin-left: auto;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  z-index: 2;
  padding-left: 15px;
  padding-right: 15px;
}

.others-option-for-responsive .container .container.active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}

.others-option-for-responsive .others-options {
  position: relative;
  top: 0;
  background-color: var(--optional-color);
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  top: 0;
  background-color: var(--white-color);
}

.others-option-for-responsive .others-options ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  line-height: 1;
}

.others-option-for-responsive .others-options ul li {
  display: inline-block;
  margin-right: 5px;
}

.others-option-for-responsive .others-options ul li .search-icon {
  font-size: 25px;
  position: relative;
  top: 6px;
  background-color: transparent;
}

.others-option-for-responsive .others-options ul li:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}

.others-option-for-responsive .others-options ul li:nth-child(4) {
  border-right: none;
}

.others-option-for-responsive .others-options ul li .navbar-option-language {
  position: relative;
  top: 2px;
}

.others-option-for-responsive .others-options ul li .navbar-option-language button {
  background-color: transparent;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 15px;
  font-weight: 500;
  color: var(--heading-color);
}

.others-option-for-responsive .others-options ul li .navbar-option-language button img {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 35px;
          flex: 0 0 35px;
  max-width: 35px;
  border-radius: 50%;
  border: 3px solid var(--white-color);
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
  margin-right: 10px;
}

.others-option-for-responsive .others-options ul li .navbar-option-language button i {
  font-size: 20px;
  margin-right: 10px;
  line-height: 1;
  color: var(--heading-color);
}

.others-option-for-responsive .others-options ul li .navbar-option-language .dropdown-toggle:after {
  content: "\ea4e";
  font-family: 'remixicon' !important;
  font-size: 20px;
  margin-left: 5px;
  border: 0;
}

.others-option-for-responsive .others-options ul li .navbar-option-language .dropdown-menu {
  margin: 0;
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
  display: block !important;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  right: 0;
  left: auto;
  border-radius: 0;
  padding: 8px 0;
  border: none;
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
  background-color: var(--white-color);
}

.others-option-for-responsive .others-options ul li .navbar-option-language .dropdown-menu .dropdown-item {
  padding: 8px 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 15px;
}

.others-option-for-responsive .others-options ul li .navbar-option-language .dropdown-menu .dropdown-item img {
  max-width: 25px;
  width: 25px;
  margin-right: 10px;
}

.others-option-for-responsive .others-options ul li .navbar-option-language .dropdown-menu .dropdown-item:hover {
  color: var(--main-color);
  background-color: transparent;
}

.others-option-for-responsive .others-options ul li .navbar-option-language .dropdown-menu .dropdown-item:focus {
  color: var(--main-color);
  background-color: transparent;
}

.others-option-for-responsive .others-options ul li .navbar-option-language .dropdown-menu.show {
  -webkit-transform: translateY(26px);
          transform: translateY(26px);
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.others-option-for-responsive .others-options ul .default-btn {
  padding: 10px 15px;
  font-size: 14px;
}

/*
Navbar Responsive Area Style
======================================================*/
/* Mobile and iPad Navbar */
@media only screen and (max-width: 991px) {
  .navbar-area {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .mobile-responsive-nav {
    display: block;
  }
  .mobile-responsive-nav .mobile-responsive-menu {
    position: relative;
  }
  .mobile-responsive-nav .mobile-responsive-menu.mean-container .mean-nav {
    margin-top: 50px;
    background-color: var(--white-color);
  }
  .mobile-responsive-nav .mobile-responsive-menu.mean-container .mean-nav ul {
    font-size: 15px;
    border: none !important;
  }
  .mobile-responsive-nav .mobile-responsive-menu.mean-container .mean-nav ul li a {
    color: var(--black-color);
    border-top-color: #dbeefd;
    text-transform: capitalize;
  }
  .mobile-responsive-nav .mobile-responsive-menu.mean-container .mean-nav ul li a i {
    display: none;
  }
  .mobile-responsive-nav .mobile-responsive-menu.mean-container .mean-nav ul li a.mean-expand {
    width: 100%;
    height: 28px;
    text-align: right;
    padding: 11px !important;
    background: transparent !important;
    border-left: none !important;
    border-bottom: none !important;
  }
  .mobile-responsive-nav .mobile-responsive-menu.mean-container .mean-nav ul li a.active {
    color: var(--main-color);
  }
  .mobile-responsive-nav .mobile-responsive-menu.mean-container .mean-nav ul li li a {
    font-size: 15px;
  }
  .mobile-responsive-nav .mobile-responsive-menu.mean-container .navbar-nav {
    -webkit-box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
            box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.1);
    margin-top: 0 !important;
    max-height: 50vh;
    overflow-y: scroll;
    /* width */
    /* Track */
    /* Handle */
    /* Handle on hover */
  }
  .mobile-responsive-nav .mobile-responsive-menu.mean-container .navbar-nav::-webkit-scrollbar {
    width: 7px;
  }
  .mobile-responsive-nav .mobile-responsive-menu.mean-container .navbar-nav::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .mobile-responsive-nav .mobile-responsive-menu.mean-container .navbar-nav::-webkit-scrollbar-thumb {
    background: #888;
  }
  .mobile-responsive-nav .mobile-responsive-menu.mean-container .navbar-nav::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .mobile-responsive-nav .mean-container a.meanmenu-reveal {
    top: 0;
    padding: 0;
    width: 35px;
    height: 30px;
    padding-top: 13px;
    color: var(--main-color);
  }
  .mobile-responsive-nav .mean-container a.meanmenu-reveal span {
    background: var(--main-color);
    height: 4px;
    margin-top: -6px;
    border-radius: 0;
    position: relative;
    top: 8px;
  }
  .mobile-responsive-nav .mean-container .mean-bar {
    background: transparent;
    position: absolute;
    z-index: 999;
    padding: 0;
  }
  .mobile-responsive-nav .mean-container .navbar-nav .nav-item.mega-menu .dropdown-menu .nav-item .row {
    display: block;
    -ms-flex-wrap: unset;
        flex-wrap: unset;
    margin-left: 0;
    margin-right: 0;
    margin-top: -20px;
  }
  .mobile-responsive-nav .mean-container .navbar-nav .nav-item.mega-menu .dropdown-menu .nav-item .row .col {
    -ms-flex-preferred-size: unset;
        flex-basis: unset;
    -webkit-box-flex: unset;
        -ms-flex-positive: unset;
            flex-grow: unset;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
    padding-top: 20px;
  }
  .mobile-responsive-nav .mean-container .navbar-nav .nav-item.mega-menu .dropdown-menu .nav-item .row .col .sub-menu-title {
    padding: 1em 10%;
  }
  .mobile-responsive-nav .mean-container .navbar-nav .nav-item.mega-menu .dropdown-menu .sub-menu {
    width: auto;
    overflow: hidden;
    display: block !important;
    padding-left: 0;
    padding-right: 0;
  }
  .mobile-responsive-nav .mean-container .navbar-nav .nav-item.mega-menu .dropdown-menu .sub-menu li a {
    padding: 1em 10%;
  }
  .mobile-responsive-nav .mean-container .navbar-nav .nav-item.mega-menu .dropdown-menu .sub-menu li:last-child a {
    padding-bottom: 0;
  }
  .mobile-responsive-nav .others-options {
    display: none !important;
  }
  .mobile-responsive-nav .logo {
    position: relative;
    width: 130px;
    z-index: 999;
  }
  .desktop-nav {
    display: none;
  }
  .others-option-for-responsive {
    display: block;
  }
  .desktop-nav .navbar .others-options ul li .social-link {
    display: none;
  }
}
/*# sourceMappingURL=navbar.css.map */
