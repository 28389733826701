.responsive-tabs.nav-tabs {
  position: relative;
  z-index: 10;
  height: 42px;
  overflow: visible;
  border-bottom: none;
}
@media (min-width: 768px) {
  .responsive-tabs.nav-tabs {
    display: block;
  }
}
@media (max-width: 768px) {
  .responsive-tabs.nav-tabs {
    display: block;
    flex-wrap: none;
  }
}
.responsive-tabs.nav-tabs i.fa {
  position: absolute;
  top: 14px;
  right: 22px;
}
.responsive-tabs.nav-tabs i.fa.fa-caret-up {
  display: none;
}
@media (min-width: 768px) {
  .responsive-tabs.nav-tabs i.fa {
    display: none;
  }
}
.responsive-tabs.nav-tabs > li {
  display: none;
  float: none;
  text-align: center;
}
.responsive-tabs.nav-tabs > li:last-of-type > a {
  margin-right: 0;
}
.responsive-tabs.nav-tabs > li > a {
  margin-right: 0;
  background: #fff;
  border: 1px solid #DDDDDD;
}
@media (min-width: 768px) {
  .responsive-tabs.nav-tabs > li > a {
    margin-right: 4px;
  }
}
.responsive-tabs.nav-tabs > li.active {
  display: block;
}
.responsive-tabs.nav-tabs > li.active a {
  border: 1px solid #DDDDDD;
  border-radius: 2px;
}
@media (min-width: 768px) {
  .responsive-tabs.nav-tabs > li.active a {
    border-bottom-color: transparent;
  }
}
@media (min-width: 768px) {
  .responsive-tabs.nav-tabs > li {
    display: block;
    float: left;
  }
}
.responsive-tabs.nav-tabs.open i.fa.fa-caret-up {
  display: block;
}
@media (min-width: 768px) {
  .responsive-tabs.nav-tabs.open i.fa.fa-caret-up {
    display: none;
  }
}
.responsive-tabs.nav-tabs.open i.fa.fa-caret-down {
  display: none;
}
.responsive-tabs.nav-tabs.open > li {
  display: block;
}
.responsive-tabs.nav-tabs.open > li a {
  border-radius: 0;
}
.responsive-tabs.nav-tabs.open > li:first-of-type a {
  border-radius: 2px 2px 0 0;
}
.responsive-tabs.nav-tabs.open > li:last-of-type a {
  border-radius: 0 0 2px 2px;
}
