/*! #######################################################################

	MeanMenu 2.0.7
	--------

	To be used with jquery.meanmenu.js by Chris Wharton (http://www.meanthemes.com/plugins/meanmenu/)

####################################################################### */
a.meanmenu-reveal {
	display: none
}

.mean-container .mean-bar {
	float: left;
	width: 100%;
	position: relative;
	background: #0c1923;
	padding: 4px 0;
	min-height: 42px;
	z-index: 999999
}

.mean-container a.meanmenu-reveal {
	width: 22px;
	height: 22px;
	padding: 13px 13px 11px;
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
	color: #fff;
	text-decoration: none;
	font-size: 16px;
	text-indent: -9999em;
	line-height: 22px;
	font-size: 1px;
	display: block;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: 700
}

.mean-container a.meanmenu-reveal span {
	display: block;
	background: #fff;
	height: 3px;
	margin-top: 3px
}

.mean-container .mean-nav {
	float: left;
	width: 100%;
	background: #0c1923;
	margin-top: 44px
}

.mean-container .mean-nav ul {
	padding: 0;
	margin: 0;
	width: 100%;
	list-style-type: none
}

.mean-container .mean-nav ul li {
	position: relative;
	float: left;
	width: 100%
}

.mean-container .mean-nav ul li a {
	display: block;
	float: left;
	width: 90%;
	padding: 1em 5%;
	margin: 0;
	text-align: left;
	color: #fff;
	border-top: 1px solid #383838;
	border-top: 1px solid rgba(255, 255, 255, .5);
	text-decoration: none;
	text-transform: uppercase
}

.mean-container .mean-nav ul li li a {
	width: 80%;
	padding: 1em 10%;
	border-top: 1px solid #f1f1f1;
	border-top: 1px solid rgba(255, 255, 255, .25);
	opacity: .75;
	filter: alpha(opacity=75);
	text-shadow: none !important;
	visibility: visible
}

.mean-container .mean-nav ul li.mean-last a {
	border-bottom: 0;
	margin-bottom: 0
}

.mean-container .mean-nav ul li li li a {
	width: 70%;
	padding: 1em 15%
}

.mean-container .mean-nav ul li li li li a {
	width: 60%;
	padding: 1em 20%
}

.mean-container .mean-nav ul li li li li li a {
	width: 50%;
	padding: 1em 25%
}

.mean-container .mean-nav ul li a:hover {
	background: #252525;
	background: rgba(255, 255, 255, .1)
}

.mean-container .mean-nav ul li a.mean-expand {
	margin-top: 1px;
	width: 26px;
	height: 32px;
	padding: 12px !important;
	text-align: center;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 2;
	font-weight: 700;
	background: rgba(255, 255, 255, .1);
	border: 0 !important;
	border-left: 1px solid rgba(255, 255, 255, .4) !important;
	border-bottom: 1px solid rgba(255, 255, 255, .2) !important
}

.mean-container .mean-nav ul li a.mean-expand:hover {
	background: rgba(0, 0, 0, .9)
}

.mean-container .mean-push {
	float: left;
	width: 100%;
	padding: 0;
	margin: 0;
	clear: both
}

.mean-nav .wrapper {
	width: 100%;
	padding: 0;
	margin: 0
}

.mean-container .mean-bar,
.mean-container .mean-bar * {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box
}

.mean-remove {
	display: none !important
}