/*
@File: Leud Template Styles

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the template.

This files table contents are outlined below>>>>>

*******************************************
** - DEFAULT AREA STYLE - **

** - Default Btn Area Style
** - Read More Area Style
** - Section Title Area Style

** - HOME PAGE STYLE - **

** - Header Area Style
** - Nav Area Style
** - Banner Area Style
** - Partner Area Style
** - Our Mission Area Style
** - About Area Style
** - Lines Area Style
** - Our Causes Area Style
** - What We Do Area Style
** - Counter Area Style
** - Donation Area Style
** - How We Can Area Style
** - Gallery Area Style
** - Event Area Style
** - Testimonial Area Style
** - Volunteers Area Style
** - Blog Area Style
** - Subscribe Area Style
** - Footer Area Style
** - Copy Right Area Style

** - OTHER STYLE AREA - **

** - Preloader Area Style
** - Go Top Style
** - Video wave Style
** - Section Title Area Style
** - Nice select Area

*******************************************
/*

/*
Every Pages Style Area
======================================================*/
/*
Default Style
============================*/
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,700&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Paytone+One&amp;display=swap");

:root {
  --body-font-family: "Roboto", sans-serif;
  --heading-font-family: "Paytone One", sans-serif;
  --body-color: #7b7b7b;
  --main-color: #f7941d;
  --optional-color: #e61e40;
  --heading-color: #0f1514;
  --white-color: #ffffff;
  --black-color: #000000;
  --font-size: 15px;
  --transition: all ease 0.5s;
  --border-radius: 4px;
  --box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
}

body {
 
  font-size: var(--font-size);
  font-family: var(--body-font-family);
  /* color: var(--body-color); */
  /* background-image: url('../img/7.jpg'); */
}

body.body-bg-f7f6f1 {
  background-color: #f7f6f1;
}

a {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-decoration: none;
  color: var(--body-color);
}

a:hover {
  text-decoration: none;
  color: #0286cd;
}

a:focus {
  text-decoration: none;
}

button {
  outline: 0 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  padding: 0;
}

button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-color);
  font-family: var(--heading-font-family);
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  vertical-align: middle;
}

p {
  font-size: var(--font-size);
  margin-bottom: 15px;
  line-height: 1.8;
}

p:last-child {
  margin-bottom: 0;
}

img {
  max-width: 100%;
  height: auto;
}

.form-control {
  height: 32px;
  color: #0286cd;
  border: 1px solid #d7d7d7;
  background-color: transparent;
  border-radius: 0;
  font-size: 13px;
  padding: 10px 20px;
  width: 60%;
  outline: 0;
  /* border-width: 0 0 2px; */
}
.tp {
  margin-top: 15px;
}

.form-control::-webkit-input-placeholder {
  color: #676a6a;
}

.form-control:-ms-input-placeholder {
  color: #676a6a;
}

.form-control::-ms-input-placeholder {
  color: #676a6a;
}

.form-control::placeholder {
  color: #676a6a;
}

.form-control:focus {
  color: var(--black-color);
  background-color: transparent;
  -webkit-box-shadow: unset;
  box-shadow: unset;
  outline: 0;
  border: 1px solid#0286CD;
}

.form-control:hover:focus,
.form-control:focus {
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

textarea.form-control {
  height: auto;
}

.ptb-100 {
  padding-top: 0px;
  padding-bottom: 50px;
}

.ptb-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.pt-100 {
  padding-top: 30px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pt-70 {
  padding-top: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-40 {
  padding-bottom: 40px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-minus-100 {
  margin-top: -100px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.pr-15 {
  padding-right: 15px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.pl-0 {
  padding-left: 0;
}

.pr-0 {
  padding-right: 0;
}

/*
Bg-color Style*/
/* .bg-f9fafb {
  background-color: #f9fafb;
} */

/*
Default Btn Area Style*/
.default-btn {
  font-size: 15px;
  color: #000;

  font-weight: 600;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-align: center;
  position: relative;
  /* z-index: 1; */
  overflow: hidden;
  display: inline-table;
  padding: 09px 28px;
  background-color: #59a5f100;
  /* background-color: #0286CD; */
  border: solid 2px #0286cd;
}

.default-btn i {
  position: relative;
  top: 2px;
  font-size: 16px !important;
  color: var(--white-color) !important;
  margin-left: 5px;
}

.default-btn.border-btn {
  border: 1px solid #0286cd;
  color: #0286cd;
  background-color: transparent;
}
.glowing{
background-color: #0286cd;
color: rgb(2, 134, 205);
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}

@-webkit-keyframes glowing {
  0% { background-color: rgba(240, 136, 33, 0.575); -webkit-box-shadow: 0 0 3px rgba(240, 136, 33, 0.575); }
  50% { background-color: rgba(240, 136, 33, 0.774); -webkit-box-shadow: 0 0 40px rgba(240, 136, 33, 0.774); }
  100% { background-color: rgb(240,136,33); -webkit-box-shadow: 0 0 3px rgb(240,136,33); }
}

@-moz-keyframes glowing {
  0% { background-color: #f59827cc; -moz-box-shadow: 0 0 3px #f59827cc; }
  50% { background-color: #eba44e67; -moz-box-shadow: 0 0 40px #eba44e67; }
  100% { background-color: #f59827cc; -moz-box-shadow: 0 0 3px #f59827cc; }
}

@-o-keyframes glowing {
  0% { background-color: #f59827cc; -webkit-box-shadow: 0 0 3px #f59827cc; }
  50% { background-color: #eba44e67; -webkit-box-shadow: 0 0 40px #eba44e67; }
  100% { background-color: #f59827cc; -webkit-box-shadow: 0 0 3px #f59827cc; }
}

@keyframes glowing {
  0% { background-color: #f59827cc; -webkit-box-shadow: 0 0 3px #f59827cc; }
  50% { background-color: #eba44e67; -webkit-box-shadow: 0 0 40px #eba44e67; }
  100% { background-color: #f59827cc; -webkit-box-shadow: 0 0 3px #f59827cc; }
}

.default-btn.border-btn:hover {
  background-color: #0286cd;
  color: var(--white-color);
}

.default-btn.border-radius-btn {
  border-radius: 50px;
}

.default-btn.bg-btn {
  background-color: #0286cd;
  border-radius: 50px;
}

.default-btn::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #0286cd;
  z-index: -1;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.default-btn:hover {
  color: var(--white-color);
  background-color: #0286cd;
}

.default-btn:hover::after {
  height: 100%;
  bottom: auto;
  top: 0;
  -webkit-animation: fadeInLeft 1s linear;
  animation: fadeInLeft 1s linear;
}

/*Read More Btn Area Style*/
.read-more {
  font-size: 14px;
  color: var(--heading-color);
  font-weight: 500;
}

.read-more i {
  position: relative;
  top: 3px;
}

.read-more:hover {
  color: #0286cd;
  letter-spacing: 1px;
}

/*Section Title Area Style*/

.section-title {
  /* max-width: 700px; */
  margin: -7px auto 50px;
  text-align: center;
  position: relative;
  z-index: 1;
}

.section-title .top-title {
  font-size: 22px;
  color: #0286cd;
  margin-bottom: 8px;
  display: inline-block;
}

.section-title .watermark {
  color: #ececec;
  font-size: 60px;
  font-size: 70px;
  font-family: var(--heading-font-family);
  position: absolute;
  top: -45px;
  left: 0;
  right: 0;
  z-index: -1;
  line-height: 1;
  display: none;
}

.section-title h2 {
  font-size: 25px;
  margin-bottom: 15px;
}

.section-title h2:last-child {
  margin-bottom: 0;
}

.section-title p {
  max-width: 600px;
  margin: auto;
}

.section-title.section-title-left {
  /* margin-left: 0; */
  margin-right: auto;
  /* text-align: left; */
}

.section-title.section-title-three .top-title {
  color: #0286cd;
}

.section-title.section-white-title .top-title {
  color: #0286cd;
}

.section-title.section-white-title h2 {
  color: var(--white-color);
}

/*Search Modal Area Style ======================================================*/
.search-modal-area .modal-content {
  height: 50vh;
  border: none;
  border-radius: 0;
  background-color: rgb(75 2 5 / 72%);
  position: relative;
}

.search-modal-area .modal-content form.search-box {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.search-modal-area .modal-content form.search-box .search-input {
  width: 810px;
  position: relative;
  padding: 30px;
}

.search-modal-area .modal-content form.search-box .search-input .form-control {
  color: var(--white-color);
  height: 60px;
}

.search-modal-area
  .modal-content
  form.search-box
  .search-input
  .form-control::-webkit-input-placeholder {
  color: #eeeeee;
}

.search-modal-area
  .modal-content
  form.search-box
  .search-input
  .form-control:-ms-input-placeholder {
  color: #eeeeee;
}

.search-modal-area
  .modal-content
  form.search-box
  .search-input
  .form-control::-ms-input-placeholder {
  color: #eeeeee;
}

.search-modal-area
  .modal-content
  form.search-box
  .search-input
  .form-control::placeholder {
  color: #eeeeee;
}

.search-modal-area .modal-content form.search-box .search-input .search-btn {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 60px;
  height: 60px;
  line-height: 63px;
  font-size: 25px;
  background-color: var(--white-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.search-modal-area
  .modal-content
  form.search-box
  .search-input
  .search-btn:hover {
  color: var(--white-color);
  background-color: #0286cd;
}

.search-modal-area .modal-content .closer-btn {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-color: #ff3131;
  color: var(--white-color);
  font-size: 20px;
  z-index: 1;
}

.search-modal-area .modal-dialog {
  max-width: 100%;
  margin: 0;
}

.search-modal-area.modal {
  overflow: hidden;
  padding: 0 !important;
}

/*
Banner Area Style
=====================================================*/
.banner-area {
  background-position: center;
  background-repeat: no-repeat;
  /* border-bottom: 1px solid #ebf1e5; */
  background-size: cover;
  height: 100%;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.banner-area.used-hight {
  height: 800px;
}

.banner-area.banner-three-style {
  background-image: url("../images/banner/banner-bg-1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  max-width: 100%;
  position: relative;
  border: none;
  z-index: 1;
  padding-top: 150px;
  padding-bottom: 150px;
}

.banner-area.banner-three-style::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: -1;
  -webkit-clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
}

.banner-area.banner-three-style .banner-content .top-title {
  color: #0286cd;
}

.banner-area.banner-three-style .banner-content h1 {
  color: var(--white-color);
}

.banner-area.banner-three-style .banner-content p {
  color: var(--white-color);
}

.banner-area .banner-img-2 {
  position: absolute;
  top: 40px;
  right: 30px;
  max-width: 600px;
}

.banner-area .banner-img-2 .banner-imgs {
  padding: 30px;
}

.banner-area .banner-img-2 .banner-border-shape {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.banner-area .banner-img-2 .what-we-do-play {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.banner-area .banner-shape-1 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  max-width: 1065px;
}

.banner-area .shape-1 {
  position: absolute;
  top: 80px;
  left: 0;
  z-index: -1;
  opacity: 0.5;
}

.banner-area .shape-2 {
  position: absolute;
  top: 100px;
  right: 100px;
  z-index: -1;
  opacity: 0.5;
}

.banner-area .banner-img {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 680px;
}

.banner-content {
  max-width: 645px;
}

.banner-content .top-title {
  font-size: 14px;
  padding: 100px 0px 0px 0px;
  color: #0286cd;
  /* margin-bottom: 5px; */
  display: block;
  font-weight: 600;
}

.banner-content h1 {
  font-size: 50px;
  /* padding: 113px 0px 0px 0px; */
  color: var(--heading-color);
  margin-bottom: 25px;
}

.banner-content p {
  margin-bottom: 24px;
}

.banner-content .banner-btn .default-btn {
  margin-right: 30px;
}

.banner-content .banner-btn .call-wrap {
  position: relative;
  padding-left: 70px;
  top: 13px;
  display: inline-block;
}

.banner-content .banner-btn .call-wrap .call-us {
  color: var(--body-color);
}

.banner-content .banner-btn .call-wrap .call-us i {
  position: absolute;
  top: 0;
  left: 0;
  width: 54px;
  height: 54px;
  line-height: 54px;
  background-color: #0286cd;
  color: var(--white-color);
  text-align: center;
  border-radius: 50%;
  font-size: 25px;
  top: -4px;
}

.banner-content .banner-btn .call-wrap .call-us span {
  font-weight: 600;
  color: #0286cd;
}

.banner-content .social-link {
  padding: 0;
  margin: 0;
  list-style-type: none;
  margin-top: 150px;
}

.banner-content .social-link li {
  display: inline-block;
  margin-right: 10px;
}

.banner-content .social-link li span {
  color: var(--heading-color);
  margin-right: 100px;
  position: relative;
  top: -2px;
}

.banner-content .social-link li span::before {
  content: "";
  position: absolute;
  top: 11px;
  right: -95px;
  width: 80px;
  height: 1px;
  background-color: #7b7b7b;
}

.banner-content .social-link li:last-child {
  margin-right: 0;
}

.banner-content .social-link li a i {
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-color: #7b7b7b;
  border-radius: 50%;
  color: var(--white-color);
  display: inline-block;
  text-align: center;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.banner-content .social-link li a:hover i {
  background-color: #0286cd;
}

.help-area {
  padding-top: 60px;
  padding-bottom: 60px;
  position: relative;
  z-index: 1;
}

.help-area::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 70%;
  height: 100%;
  background-color: #fff;
  -webkit-clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%);
  z-index: -1;
}

.help-area .call-wrap {
  position: relative;
  padding-left: 70px;
  top: 13px;
  display: inline-block;
}

.help-area .call-wrap .call-us {
  color: var(--body-color);
}

.help-area .call-wrap .call-us i {
  position: absolute;
  top: 0;
  left: 0;
  width: 54px;
  height: 54px;
  line-height: 54px;
  background-color: #0286cd;
  color: var(--white-color);
  text-align: center;
  border-radius: 50%;
  font-size: 25px;
  top: -4px;
}

.help-area .call-wrap .call-us span {
  font-weight: 600;
  color: #0286cd;
}

.help-content h2 {
  font-size: 28px;
  color: #000;
  text-align: center;
  margin-top: -16px;
  margin-bottom: 40px;
}

.single-help {
  position: relative;
}

.single-help h4 {
  font-size: 19px;
  /* position: absolute; */
  top: 16px;
  left: 0;
  color: #000;

  /* padding-left: 109px; */
}

.single-help p {
  font-size: 14px;
  padding: 7px;
  color: #000;
}

/*
Partner Area Style
=====================================================*/
.partner-area {
  position: relative;
  z-index: 1;
  /* background-color: #f9fafb; */
}

.partner-bg {
  text-align: center;
}

.partner-bg span {
  display: block;
  color: #0286cd;
  font-size: 18px;
  margin-bottom: 50px;
  margin-top: -7px;
}

.partner-bg .owl-carousel .owl-item img {
  width: auto;
  margin: auto;
}

.partner-bg.style-two .single-partner img {
  opacity: 1;
  -webkit-filter: none;
  filter: none;
}

.partner-bg.style-three span {
  color: #0286cd;
}

.single-partner img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.5;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  cursor: pointer;
}

.single-partner:hover img {
  opacity: 1;
}

/*
Our Mission Area Style
=====================================================*/
.our-mission-area {
  position: relative;
  z-index: 1;
}

.our-mission-area .left-shape-1 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.our-mission-area .mission-dot-shape {
  position: absolute;
  top: 0;
  right: 100px;
  z-index: -1;
}

.our-mission-area.style-three {
  background-image: url("../images/mison-bg.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: relative;
  z-index: 1;
}

.our-mission-area.style-three::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 350px;
  background-color: var(--white-color);
  z-index: -1;
}

.our-mission-area.style-three .section-title .watermark {
  color: #262c2b;
  opacity: 0.3;
}

.single-mission {
  margin-bottom: 30px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-mission img {
  margin-bottom: 20px;
}

.single-mission h3 {
  margin-bottom: 15px;
  font-size: 20px;
}

.single-mission.style-two img {
  background-color: #fffaf3;
  padding: 20px;
  border-radius: 50px;
}

.single-mission.style-three {
  background-color: #2125290f;
  padding: 30px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: relative;
}
.single-mission.style-box {
  background-color: #0286cd59;
  padding: 10px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: relative;
}
.single-mission.style-box span {
  font-family: var(--heading-font-family);
  font-size: 50px;
  color: #f0f1f1;
  position: absolute;
  top: 20px;
  right: 30px;
  line-height: 1;
}

.single-mission.style-three span {
  font-family: var(--heading-font-family);
  font-size: 50px;
  color: #f0f1f1;
  position: absolute;
  top: 20px;
  right: 30px;
  line-height: 1;
}

.single-mission.style-three:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.single-mission:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.video-bg {
  position: relative;
  margin-top: 70px;
}

.video-bg .what-we-do-play {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
}

.video-bg .video-btn::after,
.video-bg .video-btn::before {
  background-color: #0286cd;
}

.video-bg .video-btn {
  background-color: #0286cd;
}

/*
About Area Style
=====================================================*/
.about-area {
  position: relative;
  z-index: 1;
}

.about-area .right-shape-1 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.about-area.about-style-three {
  overflow: hidden;
}

.about-area.about-style-three .about-content {
  /* margin-left: 15px !important; */
  position: relative;
  text-align: justify;
  /* margin-top: 25px; */
}

.about-area.about-style-three .about-content .top-title {
  color: #f08821;
  font-size: 25px;
}

.about-content {
  position: relative;
}

.about-content .top-title {
  font-size: 14px;
  color: #0286cd;
  margin-bottom: 8px;
  display: inline-block;
}

.about-content h2 {
  font-size: 45px;
  margin-bottom: 30px;
}

.about-content strong {
  color: var(--heading-color);
  margin-bottom: 10px;
  display: block;
}

.about-content .default-btn {
  margin-top: 24px;
}

.about-content h3 {
  font-size: 30px;
  color: #6c7177;
  margin-bottom: 0;
  position: absolute;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  left: -330px;
  top: 248px;
}

.about-content ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.about-content ul li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 30px;
}

.about-content ul li i {
  width: 20px;
  height: 20px;
  line-height: 20px;
  background-color: #0286cd;
  display: inline-block;
  color: var(--white-color);
  text-align: center;
  border-radius: 50px;
  position: absolute;
  top: 3px;
  left: 0;
}

.about-content ul li:last-child {
  margin-bottom: 0;
}

.about-content.page-style h3 {
  left: -344px;
}

.about-img-2 {
  position: relative;
}

.about-img-2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(0, 0, 0, 0.8)),
    to(transparent)
  );
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.8), transparent);
  border-radius: 100% 100% 0 100%;
}

.about-img-2 img {
  border-radius: 100% 100% 0 100%;
}

.about-img-2 h3 {
  font-size: 25px;
  color: var(--white-color);
  position: absolute;
  bottom: 30px;
  right: 30px;
  max-width: 250px;
}

.about-img-2 .about-shape-1 {
  position: absolute;
  bottom: 100px;
  left: -50px;
}

.about-img-2 .about-shape-2 {
  position: absolute;
  top: 100px;
  right: 0;
}

.about-img-3 {
  position: relative;
  z-index: 1;
}

/*
Lines Area Style
======================================================*/
.lines {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  width: 64vw;
  z-index: -1;
}

.lines .line {
  position: absolute;
  width: 2px;
  height: 100%;
  top: 0;
  left: 50%;
  background: #f2f2f2;
  overflow: hidden;
}

.lines .line::after {
  content: "";
  display: block;
  position: absolute;
  height: 10px;
  width: 100%;
  top: 0;
  left: 0;
  background-color: var(--white-color);
  -webkit-animation: moveLeftBounces-one 20s linear infinite;
  animation: moveLeftBounces-one 20s linear infinite;
}

.lines .line:nth-child(1) {
  margin-left: -52.7%;
}

.lines .line:nth-child(1)::after {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.lines .line:nth-child(3)::after {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}

.lines .line:nth-child(3) {
  margin-left: 52.7%;
}

.lines .line-two {
  position: absolute;
  width: 2px;
  height: 100%;
  top: 0;
  left: 50%;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
}

.lines .line-two::after {
  content: "";
  display: block;
  position: absolute;
  height: 10px;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #0286cd;
  -webkit-animation: moveLeftBounces-two 20s linear infinite;
  animation: moveLeftBounces-two 20s linear infinite;
}

.lines .line-two:nth-child(1) {
  margin-left: -52.7%;
}

.lines .line-two:nth-child(1)::after {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.lines .line-two:nth-child(3)::after {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}

.lines .line-two:nth-child(3) {
  margin-left: 52.7%;
}

.lines .line-three {
  position: absolute;
  width: 2px;
  height: 100%;
  top: 0;
  left: 50%;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
}

.lines .line-three::after {
  content: "";
  display: block;
  position: absolute;
  height: 10px;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #0286cd;
  -webkit-animation: moveLeftBounces-one 20s linear infinite;
  animation: moveLeftBounces-one 20s linear infinite;
}

.lines .line-three:nth-child(1) {
  margin-left: -52.7%;
}

.lines .line-three:nth-child(1)::after {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.lines .line-three:nth-child(3)::after {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}

.lines .line-three:nth-child(3) {
  margin-left: 52.7%;
}

@-webkit-keyframes moveLeftBounces-one {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes moveLeftBounces-one {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes moveLeftBounces-two {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(900px);
    transform: translateY(900px);
  }

  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes moveLeftBounces-two {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(900px);
    transform: translateY(900px);
  }

  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes moveLeftBounces-three {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes moveLeftBounces-three {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

/*
Our Causes Area Style
=====================================================*/
.our-causes-area {
  /* background-image: url(../images/causes-bg.png); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  overflow: hidden;
  /* background-color: #f9fafb; */
}

.our-causes-area.our-causes-bg-2 {
  background-image: url("../images/causes-bg-2.png");
  background-position: top left;
  background-size: contain;
}

.our-causes-area .text-right {
  text-align: right;
}

.our-causes-area .text-right .default-btn {
  border: 1px solid#0286CD;
  background-color: transparent;
  color: #0286cd;
}

.our-causes-area .text-right .default-btn:hover {
  background-color: #0286cd;
  color: var(--white-color);
}

.our-causes-area .owl-theme .owl-nav {
  margin-top: 0;
  line-height: 1;
}

.our-causes-area .owl-theme .owl-nav .owl-prev,
.our-causes-area .owl-theme .owl-nav .owl-next {
  background-color: transparent;
  margin: 0 10px;
}

.our-causes-area .owl-theme .owl-nav .owl-prev i,
.our-causes-area .owl-theme .owl-nav .owl-next i {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: transparent;
  display: inline-block;
  color: #0286cd;
  border: 1px solid#0286CD;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: 50px;
}

.our-causes-area .owl-theme .owl-nav .owl-prev i::after,
.our-causes-area .owl-theme .owl-nav .owl-next i::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #0286cd;
  z-index: -1;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.our-causes-area .owl-theme .owl-nav .owl-prev:hover i,
.our-causes-area .owl-theme .owl-nav .owl-next:hover i {
  color: var(--white-color);
}

.our-causes-area .owl-theme .owl-nav .owl-prev:hover i::after,
.our-causes-area .owl-theme .owl-nav .owl-next:hover i::after {
  height: 100%;
  bottom: auto;
  top: 0;
  -webkit-animation: fadeInLeft 1s linear;
  animation: fadeInLeft 1s linear;
}

.single-causes {
  /* margin-bottom: 30px; */
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-causes .causes-img {
  position: relative;
  margin-bottom: 25px;
}

.single-causes .causes-img .tag {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: var(--white-color);
  background-color: #f08821;
  padding: 10px 20px;
  font-size: 15px;
}

.single-causes h3 {
  font-size: 18px;
  height:50px;
  /* margin-bottom: 15px;
  margin-top: -5px; */
}

.single-causes h3 a {
  color: #000;
}
/* 
.single-causes p {
  margin-bottom: 25px;
} */

.single-causes .bar {
  width: 100%;
  height: 5px;
  background: #e6e6e6;
  position: relative;
  -webkit-transition: all 2s linear;
  transition: all 2s linear;
  margin-bottom: 25px;
  margin-right: 50px;
  padding-right: 50px;
}

.single-causes .bar::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fffbf4;
  height: 100%;
  width: 50px;
}

.single-causes .bar-1,
.single-causes .my-skills {
  width: 100%;
  margin: 10px auto 25px;
  padding-right: 0;
  height: 220px!important;
}

.single-causes .bar-inner {
  width: 0;
  height: 5px;
  line-height: 35px;
  background: #0286cd;
}

.single-causes .bar-percent {
  position: absolute;
  right: 0;
  top: -9px;
  font-size: 16px;
  color: var(--heading-color);
}

.single-causes .total-donate {
  padding: 0;
  list-style-type: none;
  margin-bottom: 25px;
}

.single-causes .total-donate li {
  display: inline-block;
  font-size: 15px;
}

.single-causes .total-donate li span {
  color: #0286cd;
}

.single-causes .total-donate .goal {
  float: right;
}

.single-causes .donate-read {
  padding: 0;
  list-style-type: none;
  margin-bottom: 0;
}

.single-causes .donate-read .default-btn {
  margin-right: 0;
  border: 1px solid#0286CD;
  background-color: transparent;
  color: #0286cd;
}

.single-causes .donate-read .default-btn:hover {
  background-color: #0286cd;
  color: var(--white-color);
}

.single-causes.style-two .causes-img {
  margin-bottom: 0;
}

.causes-img img {
  width: 98% !important;

}

.single-causes.style-two .bar-1 {
  -webkit-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
  background-color: #ececec2e;
  margin-top: 0;
  padding: 17px;
}
.single-causes.style-two .bar-1 p {
  font-size: 13px;
  height: 60px;
}
.single-causes.style-two .bar-percent {
  right: 0;
}

.single-causes.style-two .bar::before {
  background-color: var(--white-color);
}

.single-causes.style-three .causes-img {
  margin-bottom: 0;
}

.single-causes.style-three .bar-1 {
  background-color: #fff;
  border: 1px solid #eee;
  margin-top: 0;
  padding: 30px;
}

.single-causes.style-three .causes-img .tag {
  background-color: #0286cd;
}

.single-causes.style-three .bar-inner {
  background-color: #0286cd;
}

.single-causes.style-three .total-donate li span {
  color: #0286cd;
}

.single-causes.style-three .bg-btn {
  border-color: #0286cd !important;
  color: var(--black-color) !important;
}

.single-causes.style-three .bg-btn:hover {
  background-color: #0286cd !important;
  color: var(--white-color) !important;
}

.single-causes .default-btn {
  padding: 10px 22px;
  font-size: 14px;
}

.single-causes .read-more {
  float: right;
  position: relative;
  top: 14px;
}

.single-causes:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.our-causess-area {
  position: relative;
}

.our-causess-area .owl-theme .owl-nav {
  margin-top: 0;
  line-height: 1;
}

.our-causess-area .owl-theme .owl-nav .owl-prev,
.our-causess-area .owl-theme .owl-nav .owl-next {
  background-color: transparent;
  margin: 0 10px;
}

.our-causess-area .owl-theme .owl-nav .owl-prev i,
.our-causess-area .owl-theme .owl-nav .owl-next i {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: transparent;
  display: inline-block;
  color: #0286cd;
  border: 1px solid #0286cd;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: 50px;
}

.our-causess-area .owl-theme .owl-nav .owl-prev i::after,
.our-causess-area .owl-theme .owl-nav .owl-next i::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #0286cd;
  z-index: -1;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.our-causess-area .owl-theme .owl-nav .owl-prev:hover i,
.our-causess-area .owl-theme .owl-nav .owl-next:hover i {
  color: var(--white-color);
}

.our-causess-area .owl-theme .owl-nav .owl-prev:hover i::after,
.our-causess-area .owl-theme .owl-nav .owl-next:hover i::after {
  height: 100%;
  bottom: auto;
  top: 0;
  -webkit-animation: fadeInLeft 1s linear;
  animation: fadeInLeft 1s linear;
}

.our-causess-area .owl-theme .owl-nav .owl-next {
  left: auto;
  right: -87px;
}

/*
What We Do Area Style
=====================================================*/
.what-we-do-area {
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.what-we-do-area .what-we-do-shape-area {
  position: absolute;
  top: 10%;
  left: 0;
  z-index: -1;
}

.what-we-do-content .top-title {
  font-size: 14px;
  color: #0286cd;
  margin-bottom: 8px;
  display: inline-block;
}

.what-we-do-content h2 {
  font-size: 45px;
  margin-bottom: 20px;
}

.what-we-do-content h2:last-child {
  margin-bottom: 0;
}

.what-we-do-content ul {
  padding: 0;
  list-style: none;
  margin-top: 14px;
}

.what-we-do-content ul li {
  margin-bottom: 15px;
  position: relative;
  padding-left: 30px;
  z-index: 1;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--heading-color);
  font-size: 15px;
}

.what-we-do-content ul li:last-child {
  margin-bottom: 0;
}

.what-we-do-content ul li i {
  font-size: 18px;
  color: var(--white-color);
  background-color: #0286cd;
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.what-we-do-img {
  position: relative;
}

.what-we-do-img .what-we-do-play {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.what-we-do-img .what-we-do-shape {
  position: absolute;
  top: 0;
  right: -50px;
}

/*
Counter Area Style
=====================================================*/
.counter-area.style-three {
  position: relative;
}

.counter-bg {
  background-image: unset;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
}

.single-counter {
  text-align: center;
  margin-bottom: 30px;
  border: 1px solid #0286cd;
  background-color: #ffffff;
  padding: 15px;
  position: relative;
  z-index: 2;
  border-top-right-radius: 46px;
  border-bottom-left-radius: 50px;
}

.single-counter.style-three h2 {
  color: #0286cd;
}

.single-counter img {
  margin-bottom: 8px;
}

.single-counter h2 {
  font-size: 35px;
  color: #0286cd;
  margin-bottom: 7px;
}

.single-counter h2 .odometer {
  font-family: var(--heading-font-family);
}

.single-counter h2 .target {
  position: relative;
  top: 7px;
}

.single-counter h4 {
  font-size: 16px;
  margin-bottom: 0;
}

/*
Donation Area Style
=====================================================*/
.donation-content img {
  margin-bottom: 20px;
}

.donation-content h2 {
  font-size: 45px;
  margin-bottom: 30px;
}

.donation-content h5 {
  font-size: 20px;
}

.donation-content ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  margin-top: 30px;
}

.donation-content ul li {
  display: inline-block;
  margin-right: 20px;
}

.donation-content ul li a {
  color: #0286cd;
  font-weight: 600;
}

.donation-content ul li span {
  color: var(--heading-color);
}

.donation-content ul li:last-child {
  margin-right: 0;
}
.donation-bg-volanteer {
  background-image: url("../images/volunteerbanner.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: relative;
  z-index: 1;
  padding: 10px;
}
.donation-bg {
  background-image: url("../images/donation-bg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: relative;
  z-index: 1;
  padding: 10px;
}

.donation-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #7974761c;
  z-index: -1;
}

.donation-bg.donation-bg-three {
  padding-bottom: 100px;
  background-image: url("../images/donation-bg-three.jpg");
}

.donation-bg.donation-bg-three::before {
  display: none;
}

.donation-bg.donation-bg-three::after {
  display: none;
}

.donation-bg.donation-bg-three .single-check .checkmark {
  border-color: #0286cd;
}

.donation-bg.donation-bg-three .single-check .checkmark:after {
  background-color: #0286cd;
}

.donation-bg.donation-bg-three .single-check:hover input ~ .checkmark {
  background-color: #0286cd;
}

.donation-bg.donation-bg-three .stap-lavel {
  padding: 0;
  margin: 0;
  list-style-type: none;
  float: right;
}

.donation-bg.donation-bg-three .stap-lavel li {
  margin-bottom: 68px;
  position: relative;
}

.donation-bg.donation-bg-three .stap-lavel li::before {
  content: "";
  position: absolute;
  bottom: -68px;
  left: 35px;
  width: 2px;
  height: 100%;
  background-color: #0286cd;
}

.donation-bg.donation-bg-three .stap-lavel li:last-child {
  margin-bottom: 0;
}

.donation-bg.donation-bg-three .stap-lavel li:last-child::before {
  display: none;
}

.donation-bg.donation-bg-three .stap-lavel li span {
  background-color: var(--white-color);
  border: 2px solid#0286CD;
  color: #0286cd;
  width: 70px;
  height: 70px;
  line-height: 65px;
  display: inline-block;
  text-align: center;
  font-family: var(--heading-font-family);
  font-size: 20px;
  border-radius: 50px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.donation-bg.donation-bg-three .stap-lavel li span.active {
  background-color: #0286cd;
  border-color: var(--white-color);
  color: var(--white-color);
}

.donation-bg.donation-bg-three .stap-lavel li span:hover {
  background-color: #0286cd;
  border-color: var(--white-color);
  color: var(--white-color);
}

.donation-form {
  /* background-color: #720e09a6; */
  -webkit-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
  padding: 50px;
}

.donation-form h3 {
  font-size: 25px;
  margin-bottom: 30px;
}

.donation-form h4 {
  font-size: 16px;
}

.donation-form ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.donation-form ul li {
  display: inline-block;
  margin-right: 30px;
}

.donation-form ul li:last-child {
  margin-right: 0;
}

.donation-form ul li .single-check {
  position: relative;
  padding-left: 20px;
}

.donation-form ul li .single-check input[type="radio"] {
  position: absolute;
  left: 0;
  top: 6px;
}

.donation-form .donation-wrap {
  margin-bottom: 20px;
}

.donation-form .donation-wrap .form-group {
  margin-bottom: 15px;
  margin-top: 15px;
}

.donation-form .donation-wrap .default-btn {
  width: 100%;
}

.single-check {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.single-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.single-check input:checked ~ .checkmark {
  background-color: #0286cd;
}

.single-check input:checked ~ .checkmark:after {
  display: block;
}

.single-check:hover input ~ .checkmark {
  background-color: #0286cd;
}

.single-check .checkmark {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border: 1px solid#0286CD;
  background-color: var(--white-color);
}

.single-check .checkmark:after {
  top: 1px;
  left: 1px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #0286cd;
}

.checkmark {
  position: absolute;
  top: 4.8px;
  left: 0;
  height: 12px;
  width: 12px;
  background-color: #d8d8d8;
  border-radius: 50%;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/*
How We Can Area Style
=====================================================*/
.how-we-can-area {
  position: relative;
  z-index: 1;
}

.how-we-can-area .left-shape-2 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.single-how-we-can {
  padding: 30px;
  margin-bottom: 30px;
}

.single-how-we-can img {
  background-color: #f9f5f0;
  padding: 15px;
  border-radius: 50%;
  margin-bottom: 15px;
}

.single-how-we-can h3 {
  margin-bottom: 15px;
  font-size: 25px;
}

.single-how-we-can.active {
  background-color: var(--white-color);
  -webkit-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
}

.single-how-we-can:hover {
  background-color: var(--white-color);
  -webkit-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

/*
Gallery Area Style
=====================================================*/
.gallery-area .section-title {
  max-width: 720px;
}

.gallery-area .text-right {
  text-align: right;
}

.gallery-area .text-right .default-btn {
  background-color: transparent;
  border: 1px solid#0286CD;
  color: #0286cd;
}

.gallery-area .text-right .default-btn:hover {
  background-color: #0286cd;
  color: var(--white-color);
}

.gallery-img {
  margin-bottom: 24px;
  position: relative;
}

.gallery-img a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: rgba(247, 148, 29, 0.7);
  opacity: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.gallery-img a i {
  font-size: 30px;
  color: var(--white-color);
  font-weight: normal;
}

.gallery-img:hover a {
  opacity: 1;
}

/*
Event Area Style
=====================================================*/
.event-area {
  background-image: url("../images/events/event-bg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: relative;
  z-index: 1;
  background: #f4f9ef;
}

.event-area .text-right {
  text-align: right;
}

.event-area .text-right .default-btn {
  background-color: transparent;
  border: 1px solid#0286CD;
  color: #0286cd;
}

.event-area .text-right .default-btn:hover {
  background-color: #0286cd;
  color: var(--white-color);
}

.event-area.event-bg-area {
  background-image: unset;
}

.event-area.event-bg-area.bg-ffffff {
  background-color: #ffffff;
}

.event-area.event-bg-three {
  background-image: url("../images/events/event-bg.jpg");
  position: relative;
}

.event-area.event-bg-three::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--black-color);
  opacity: 0.9;
  z-index: -1;
}

.event-area.event-bg-three::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 328px;
  background-color: var(--white-color);
  z-index: -1;
}

.event-area.event-bg-three .section-title .watermark {
  color: #060503;
  opacity: 0.7;
}

.single-event {
  margin-bottom: 30px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-event .default-btn {
  padding: 10px 22px;
  font-size: 15px;
}

.single-event.style-two .event-img {
  margin-bottom: 0;
}

.single-event.style-two .single-event-content {
  background-color: var(--white-color);
  -webkit-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
  padding: 30px;
}

.single-event.style-two .single-event-content h3 {
  font-size: 23px;
}

.single-event.style-three .event-img {
  margin-bottom: 0;
}

.single-event.style-three .event-img .date {
  background-color: #0286cd;
}

.single-event.style-three .single-event-content {
  background-color: var(--white-color);
  -webkit-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
  padding: 30px;
}

.single-event.style-three .single-event-content h3 {
  font-size: 23px;
}

.single-event.style-three .single-event-content ul li i {
  color: #0286cd;
}

.single-event.style-three .single-event-content .default-btn.bg-btn {
  background-color: transparent;
  border: 1px solid #0286cd;
  color: #0286cd;
}

.single-event.style-three .single-event-content .default-btn.bg-btn::after {
  background-color: #0286cd;
}

.single-event.style-three .single-event-content .default-btn.bg-btn:hover {
  color: var(--white-color);
}

.single-event .event-img {
  margin-bottom: 20px;
  position: relative;
}

.single-event .event-img .date {
  background-color: #0286cd;
  margin-bottom: 0;
  max-width: 100px;
  color: var(--white-color);
  text-align: center;
  font-size: 40px;
  line-height: 1;
  font-family: var(--heading-font-family);
  padding: 10px 20px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.single-event .event-img .date span {
  display: block;
  font-weight: normal;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 8px;
  font-family: var(--body-font-family);
}

.single-event ul {
  padding: 0;
  list-style-type: none;
  margin-bottom: 12px;
}

.single-event ul li {
  position: relative;
  padding-left: 22px;
  margin-bottom: 8px;
  font-size: 15px;
}

.single-event ul li:last-child {
  margin-bottom: 0;
}

.single-event ul li i {
  color: #0286cd;
  position: absolute;
  top: 0;
  left: 0;
}

.single-event h3 {
  margin-bottom: 15px;
  font-size: 24px;
}

.single-event h3 a {
  color: var(--heading-color);
}

.single-event p {
  margin-bottom: 22px;
}

.single-event:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

/*
Testimonial Area Style
=====================================================*/
.testimonial-area .owl-carousel .owl-item img {
  width: auto;
  margin: auto;
}

.testimonial-area .owl-theme .owl-nav {
  margin-top: 0;
  position: relative;
  line-height: 1;
}

.testimonial-area .owl-theme .owl-nav .owl-prev,
.testimonial-area .owl-theme .owl-nav .owl-next {
  background: transparent;
  position: absolute;
  bottom: 100px;
  right: 50px;
  margin: 0;
}

.testimonial-area .owl-theme .owl-nav .owl-prev i,
.testimonial-area .owl-theme .owl-nav .owl-next i {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #0286cd;
  display: inline-block;
  color: var(--white-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: 50px;
}

.testimonial-area .owl-theme .owl-nav .owl-prev i::after,
.testimonial-area .owl-theme .owl-nav .owl-next i::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: var(--heading-color);
  z-index: -1;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.testimonial-area .owl-theme .owl-nav .owl-prev:hover i::after,
.testimonial-area .owl-theme .owl-nav .owl-next:hover i::after {
  height: 100%;
  bottom: auto;
  top: 0;
  -webkit-animation: fadeInLeft 1s linear;
  animation: fadeInLeft 1s linear;
}

.testimonial-area .owl-theme .owl-nav .owl-prev {
  left: 50px;
  right: auto;
}

.testimonial-area.style-three {
  background-image: url("../images/testimonial/testimonial-bg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

.testimonial-area.style-three .owl-theme .owl-nav .owl-prev i,
.testimonial-area.style-three .owl-theme .owl-nav .owl-next i {
  background: transparent;
  border: 1px solid #0286cd !important;
  color: #0286cd !important;
}

.testimonial-area.style-three .owl-theme .owl-nav .owl-prev i::after,
.testimonial-area.style-three .owl-theme .owl-nav .owl-next i::after {
  background-color: #0286cd;
}

.testimonial-area.style-three .owl-theme .owl-nav .owl-prev:hover i,
.testimonial-area.style-three .owl-theme .owl-nav .owl-next:hover i {
  color: var(--white-color) !important;
}

.testimonial-area.style-three .single-testimonial-content ul li i {
  color: #0286cd;
}

.single-testimonial-content {
  text-align: center;
  /* max-width: 750px; */
  margin: auto;
}

.single-testimonial-content h3 {
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.single-testimonial-content span {
  margin-bottom: 20px;
  display: block;
}

.single-testimonial-content p {
  font-size: 20px;
  color: var(--heading-color);
  font-weight: 500;
}

.single-testimonial-content ul {
  padding: 0;
  list-style-type: none;
  margin-top: 21px;
  margin-bottom: 0;
}

.single-testimonial-content ul li {
  display: inline-block;
  margin: 0 5px;
}

.single-testimonial-content ul li i {
  color:  #f08821;
  font-size: 25px;
}

.testimonial-avatar {
  position: relative;
  display: inline-block;
}

.testimonial-avatar .avatar {
  border-radius: 50%;
}

.testimonial-avatar .quat {
  position: absolute;
  top: 10px;
  left: 10px;
}

/*
Volunteers Area Style
=====================================================*/
.volunteers-area {
  position: relative;
  z-index: 1;
}

.volunteers-area .text-right {
  float: left;
}

.volunteers-area .section-title {
  max-width: 780px;
}

.volunteers-area .right-shape {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.volunteers-area.volunteers-bg-area {
  /* background-image: url(../images/volunteers-bg.png); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background: #f3f9ef; */
}

.single-volunteers {
  /* margin-bottom: 30px; */
  position: relative;
  overflow: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border: 2px solid #eee;
}






.single-volunteers::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(0, 0, 0, 0.9)),
    to(transparent)
  );
  background-image: linear-gradient(to top, rgb(240,136,33), transparent);
  opacity: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-volunteers .volunteer-content {
  text-align: center;
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-volunteers .volunteer-content h3 {
  font-size: 20px;
  color: #f08821;
}

.single-volunteers .volunteer-content span {
  font-size: 15px;
  display: block;
  margin-bottom: 15px;
  color: var(--white-color);
}

.single-volunteers .volunteer-content ul {
  padding: 0;
  list-style-type: none;
}

.single-volunteers .volunteer-content ul li {
  display: inline-block;
  margin: 0 5px;
}

.single-volunteers .volunteer-content ul li a i {
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-color: #9c9e9e;
  display: inline-block;
  border-radius: 50%;
  color: var(--white-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-volunteers .volunteer-content ul li a:hover i {
  background-color: #0286cd;
  color: var(--white-color);
}

.single-volunteers.style-three .volunteer-content ul li a:hover i {
  background-color: #0286cd;
}

.single-volunteers:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.single-volunteers:hover::before {
  -webkit-animation: fadeInUp 1s linear;
  animation: fadeInUp 1s linear;
  opacity: 1;
}

.single-volunteers:hover .volunteer-content {
  opacity: 1;
}

.volunteers-bg {
  /* background-image: url(../images/join-us-bg.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: relative;
  z-index: 1;
  padding: 100px;
}

.volunteers-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgb(0 0 0 / 34%); */
  z-index: -1;
}

.volunteers-bg .section-title {
  margin-bottom: 0;
}

.volunteers-bg .section-title .top-title {
  color: #d4d4d4;
}

.volunteers-bg .section-title h2 {
  color: var(--white-color);
}

/*
Blog Area Style
=====================================================*/
.blog-post-area {
  background-image: url('../img/background_img/bac-1.png');
  background-repeat: no-repeat;
  background-position: right;
  
  position: relative;
  z-index: 1;
}

.blog-post-area .text-right {
  text-align: right;
}

.single-blog {
  margin-bottom: 30px;
  position: relative;
}

.single-blog.style-two::before {
  display: none;
}

.single-blog.style-two .blog-content {
  position: relative;
  background-color: var(--white-color);
  -webkit-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
  z-index: 1;
}

.single-blog.style-two .blog-content ul li a {
  color: var(--body-color);
}

.single-blog.style-two .blog-content ul li a:hover {
  color: #0286cd;
}

.single-blog.style-two .blog-content h3 a {
  color: var(--heading-color);
}

.single-blog.style-two .blog-content p {
  color: var(--heading-color);
}

.single-blog.style-two.style-three .date {
  background-color: #0286cd;
}

.single-blog.style-two.style-three .blog-content ul li i {
  color: #0286cd;
}

.single-blog.style-two.style-three .blog-content ul li a:hover {
  color: #0286cd;
}

.single-blog::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-blog .date {
  background-color: #0286cd;
  margin-bottom: 0;
  max-width: 100px;
  color: var(--white-color);
  text-align: center;
  font-size: 40px;
  line-height: 1;
  font-family: var(--heading-font-family);
  padding: 10px 20px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.single-blog .date span {
  display: block;
  font-weight: normal;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 8px;
  font-family: var(--body-font-family);
}

.single-blog .blog-content {
  padding: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  z-index: 1;
}

.single-blog .blog-content ul {
  padding: 0;
  list-style-type: none;
  margin-bottom: 12px;
  line-height: 1;
}

.single-blog .blog-content ul li {
  display: inline-block;
  margin-right: 20px;
  font-size: 15px;
  position: relative;
}

.single-blog .blog-content ul li::before {
  content: "";
  position: absolute;
  top: 2px;
  right: -14px;
  width: 1px;
  height: 100%;
  background-color: var(--white-color);
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}

.single-blog .blog-content ul li:last-child {
  margin-right: 0;
}

.single-blog .blog-content ul li:last-child::before {
  display: none;
}

.single-blog .blog-content ul li i {
  position: relative;
  top: 2px;
  color: #0286cd;
  margin-right: 2px;
}

.single-blog .blog-content ul li a {
  color: var(--white-color);
}

.single-blog .blog-content h3 {
  font-size: 24px;
  margin-bottom: 10px;
  line-height: 1.4;
}

.single-blog .blog-content h3 a {
  color: var(--white-color);
}

.single-blog .blog-content p {
  color: var(--white-color);
  position: initial;
  bottom: 30px;
  left: 30px;
  right: 30px;
  opacity: 0;
  margin-top: -92px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-blog:hover::before {
  background-color: rgba(0, 0, 0, 0.9);
}

.single-blog:hover .blog-content {
  margin-top: -92px;
}

.single-blog:hover .blog-content h3 a {
  color: #0286cd;
}

.single-blog:hover .blog-content p {
  position: initial;
  opacity: 1;
  margin-top: -0;
}

/*
Subscribe Area Style
=====================================================*/
.subscribe-content h2 {
  font-size: 25px;
  margin-bottom: 0;
  /* margin-top: -80px; */
}

.newsletter-form {
  position: relative;
}

.newsletter-form .default-btn {
  position: absolute;
  top: 0;
  right: 0;
  /*padding: 16px 35px;*/
}

.newsletter-form .form-control {
  background-color: var(--white-color);
}

.newsletter-form.border-radius .form-control {
  border-radius: 50px;
}

#validator-newsletter {
  font-size: 14px;
  color: #ff0000;
  margin-top: 10px;
}

.bg-colo-f4f9ef {
  /* background-color: #f4f9ef; */
  padding: 30px 0px;
}

/*
Footer Area Style
=====================================================*/
.bg-0f1514 {
  background-color: #0f1514;
}

.bg-0f1514 .subscribe-content h2 {
  color: var(--white-color);
}

.bg-0f1514 .newsletter-form .form-control {
  border-radius: 50px;
  border-color: #222728 !important;
  color: var(--white-color);
  background-color: #222728;
}

.bg-0f1514 .newsletter-form .form-control:focus {
  border-color: #0286cd !important;
}

.bg-0f1514 .newsletter-form .default-btn {
  height: 55px;
}

.bg-0f1514 .newsletter-form .default-btn::after {
  background-color: #f14562;
}

.bg-0f1514 .single-footer-widget .social-link li h3 {
  color: var(--white-color) !important;
}

.bg-0f1514 .single-footer-widget h3 {
  color: var(--white-color);
}

.bg-0f1514 .single-footer-widget p {
  color: #c5c3c3;
}

.bg-0f1514 .single-footer-widget .news-link li a {
  color: var(--white-color);
}

.bg-0f1514 .single-footer-widget .news-link li:hover a {
  color: #0286cd;
}

.bg-0f1514 .single-footer-widget .address li {
  color: #c5c3c3;
}

.bg-0f1514 .single-footer-widget .address li .call-us {
  color: var(--white-color);
}

.bg-0f1514 .single-footer-widget .address li a {
  color: #c5c3c3;
}

.bg-0f1514 .single-footer-widget .address li a:hover {
  color: #0286cd;
}

.bg-0f1514 .single-footer-widget .social-link li a:hover i {
  background-color: #0286cd;
}

.bg-0f1514 .single-footer-widget .import-link li a {
  color: #c5c3c3;
}

.bg-0f1514 .single-footer-widget .import-link li a:hover {
  color: #0286cd;
}

.bg-0f1514 .news-link li span {
  color: #c5c3c3;
}

.bg-0f1514.copy-right-area p {
  border-color: #181e1e;
  color: #c5c3c3;
}

.bg-0f1514.copy-right-area p a {
  color: #0286cd;
}

.single-footer-widget {
  margin-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
}

.single-footer-widget p {
  margin-bottom: 25px;
}

.single-footer-widget h3 {
  font-size: 25px;
  margin-bottom: 25px;
  position: relative;
  margin-top: -5px;
}

.single-footer-widget .logo {
  margin-bottom: 20px;
  display: block;
}

.single-footer-widget .social-link {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

.single-footer-widget .social-link li {
  display: inline-block;
  margin-right: 10px;
}

.single-footer-widget .social-link li:last-child {
  margin-right: 0;
}

.single-footer-widget .social-link li h3 {
  font-size: 18px;
  margin-bottom: 0;
  margin-top: 0;
  color: var(--heading-color) !important;
}

.single-footer-widget .social-link li a i {
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-color: #e1e8ef;
  color: var(--heading-color);
  display: inline-block;
  text-align: center;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 50%;
}

.single-footer-widget .social-link li a:hover i {
  background-color: #0286cd;
  color: var(--white-color);
}

.single-footer-widget .address {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.single-footer-widget .address li {
  position: relative;
  margin-bottom: 15px;
  padding-left: 20px;
}

.single-footer-widget .address li::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 0;
  border-radius: 100px;
  width: 10px;
  height: 10px;
  /* background-color:#0286CD;   */
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}

.single-footer-widget .address li a {
  display: block;
  color: var(--body-color);
}

.single-footer-widget .address li a:hover {
  color: #0286cd;
}

.single-footer-widget .address li:last-child {
  margin-bottom: 0;
}

.single-footer-widget .address li .call-us {
  font-weight: 600;
  color: var(--heading-color);
}

.single-footer-widget .import-link {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.single-footer-widget .import-link li {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eeeeee;
}

.single-footer-widget .import-link li a {
  color: var(--body-color);
  display: block;
  position: relative;
  padding-left: 20px;
}

.single-footer-widget .import-link li a::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 0;
  border-radius: 100px;
  width: 10px;
  height: 10px;
  /* background-color:#0286CD; */
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
}

.single-footer-widget .import-link li a i {
  float: right;
}

.single-footer-widget .import-link li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.single-footer-widget .import-link li:hover::before {
  border-radius: 50%;
}

.single-footer-widget .import-link li:hover a {
  color: #0286cd;
}

/*
Copy Right Area Style
======================================================*/
.copy-right-area {
  text-align: center;
}

.copy-right-area p {
  border-top: 1px solid #ebecee;
  padding-top: 20px;
  padding-bottom: 20px;
}

.copy-right-area p i {
  position: relative;
  top: 2px;
}

.copy-right-area p a {
  color: #0286cd;
  font-weight: 600;
}

.copy-right-area p a:hover {
  color: #0286cd;
}

/*====================================================
INNER PAGES STYLE AREA
======================================================*/
/*
Page Title Area Style
=====================================================*/
.page-title-area {
  padding-top: 150px;
  padding-bottom: 0;
  position: relative;
  z-index: 1;
  background-image: url("../images/page-bg/background1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.page-title-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%;
  background-color: #2c33817a; */
  opacity: 0.9;
  z-index: -1;
}

.page-title-area .page-title-content {
  position: relative;
  margin-top: -6px;
  text-align: center;
  /* background-color: #fffbf4; */
  max-width: 600px;
  margin: auto;
  padding: 30px;
  padding-bottom: 80px;
  border-radius: 4px 4px 0 0;
  top: -15px;
}

.page-title-area .page-title-content h2 {
  margin-bottom: 15px;
  font-size: 55px;
  color: var(--heading-color);
}

.page-title-area .page-title-content ul {
  padding-left: 0;
  list-style-type: none;
  margin-top: 10px;
  margin-bottom: -5px;
}

.page-title-area .page-title-content ul li {
  display: inline-block;
  position: relative;
  font-size: 15px;
  padding-right: 15px;
  margin-left: 15px;
  color: var(--white-color);
}

.page-title-area .page-title-content ul li::before {
  content: "";
  position: absolute;
  top: 5px;
  right: -3px;
  background-color: var(--body-color);
  width: 1px;
  height: 15px;
  -webkit-transform: rotate(20deg);
  transform: rotate(20deg);
}

.page-title-area .page-title-content ul li.active {
  color: #e8891e;
}

.page-title-area .page-title-content ul li:first-child {
  margin-left: 0;
}

.page-title-area .page-title-content ul li:last-child {
  padding-right: 0;
}

.page-title-area .page-title-content ul li:last-child::before {
  display: none;
}

.page-title-area .page-title-content ul li a {
  /* color: var(--body-color); */
  color: rgb(5, 5, 5);
}

.page-title-area .page-title-content ul li a:hover {
  color: #0286cd;
}

.page-title-area .page-bg-shape {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

/*
FAQ Area Style
======================================================*/
.faq-accordion {
  position: relative;
  z-index: 1;
  max-width: 820px;
  margin: auto;
}

.faq-accordion h2 {
  text-align-last: left;
}

.faq-accordion .accordion {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}

.faq-accordion .accordion .accordion-item {
  display: block;
  margin-bottom: 25px;
  border: none;
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 25px;
  border-radius: 0;
}

.faq-accordion .accordion .accordion-item:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.faq-accordion .accordion .accordion-title {
  padding-left: 0;
  color: var(--heading-color);
  text-decoration: none;
  position: relative;
  display: block;
  font-size: 18px;
  font-weight: 600;
  z-index: 1;
}

.faq-accordion .accordion .accordion-title span {
  position: absolute;
  top: -1px;
  left: 0;
}

.faq-accordion .accordion .accordion-title i {
  position: absolute;
  right: 0;
  top: 0px;
  font-size: 20px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--white-color);
  background-color: #0286cd;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50px;
}

.faq-accordion .accordion .accordion-title.active i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.faq-accordion .accordion .accordion-title.active i::before {
  content: "\f1af";
}

.faq-accordion .accordion .accordion-content {
  display: none;
  position: relative;
  margin-top: 10px;
  padding-left: 50px;
}

.faq-accordion .accordion .accordion-content span {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 17px;
  font-weight: 600;
  color: var(--heading-color);
}

.faq-accordion .accordion .accordion-content.show {
  display: block;
}

/*
Main Contact Area Style
=====================================================*/
.main-contact-area h3 {
  font-size: 30px;
  margin-bottom: 30px;
  position: relative;
}

.main-contact-area .form-group {
  margin-bottom: 30px;
}

.main-contact-area .form-group label {
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 15px;
  color: #3e3e3e;
}

.main-contact-area .form-group .form-control {
  background-color: #f7f7f7;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-color: #eeeeee;
  border: 1px solid #b5adad;
}

.main-contact-area .form-group .form-control:focus {
  border-color: #0286cd;
}

.main-contact-area .form-group .form-control::-webkit-input-placeholder {
  color: #6b7c93;
}

.main-contact-area .form-group .form-control:-ms-input-placeholder {
  color: #6b7c93;
}

.main-contact-area .form-group .form-control::-ms-input-placeholder {
  color: #6b7c93;
}

.main-contact-area .form-group .form-control::placeholder {
  color: #6b7c93;
}

.main-contact-area .form-group.checkboxs {
  position: relative;
}

.main-contact-area .form-group.checkboxs #chb2 {
  position: absolute;
  top: 8px;
  left: 0;
}

.main-contact-area .form-group.checkboxs p {
  padding-left: 20px;
}

.main-contact-area .form-group.checkboxs p a {
  color: #0286cd;
}

.main-contact-area .form-group.checkboxs p a:hover {
  color: var(--heading-color);
}

.list-unstyled {
  color: #dc3545;
  margin-top: 10px;
  font-size: 14px;
}

.hidden {
  display: none;
}

#msgSubmit {
  margin-top: 30px;
  font-size: 20px;
}

.maps-area {
  overflow: hidden;
}

.maps-area iframe {
  width: 100%;
  height: 704px;
  display: block;
}

.contact-info-bg {
  /* background-image: url(../images/contact-info-bg.jpg); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: relative;
  z-index: 1;
  /* padding: 100px; */
  padding-bottom: 70px;
}

.contact-info-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgb(247 247 247); */
  z-index: -1;
}

.single-contact-info {
  margin-bottom: 30px;
  text-align: center;
  border: 1px solid var(--white-color);
  padding: 30px;
  background: white;
}

.single-contact-info i {
  display: inline-block;
  width: 54px;
  height: 54px;
  line-height: 54px;
  background-color: var(--white-color);
  color: #0286cd;
  text-align: center;
  border-radius: 50%;
  font-size: 25px;
  top: -4px;
  margin-bottom: 20px;
}

.single-contact-info a {
  margin-bottom: 5px;
}

.single-contact-info .call-us {
  color: #0286cd;
  display: block;
}

.single-contact-info .call-us:hover {
  color: #0286cd;
}

.single-contact-info p {
  color: #0286cd;
}

/*
User Area CSS
=====================================================*/
.user-area {
  position: relative;
  z-index: 1;
}

.user-area .user-form-content {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f6f7ff),
    to(#f0f8ff)
  );
  background: linear-gradient(to right, #f6f7ff, #f0f8ff);
  padding: 50px;
  margin: auto;
  max-width: 700px;
  margin: auto;
}

.user-area .user-form-content h3 {
  font-size: 30px;
  margin-bottom: 20px;
  position: relative;
}

.user-area .user-form-content .user-form .form-group {
  margin-bottom: 20px;
}

.user-area .user-form-content .user-form .form-group label {
  font-size: 16px;
  margin-bottom: 12px;
  color: #545252;
}

.user-area .user-form-content .user-form .form-group .form-control {
  background: var(--white-color);
}

.user-area .user-form-content .user-form .login-action {
  margin-bottom: 30px;
}

.user-area .user-form-content .user-form .login-action .log-rem {
  display: inline-block;
}

.user-area .user-form-content .user-form .login-action .log-rem label {
  margin-bottom: 0;
  font-size: 16px;
}

.user-area .user-form-content .user-form .login-action .log-rem a {
  color: #0286cd;
}

.user-area .user-form-content .user-form .login-action .forgot-login {
  display: inline-block;
  float: right;
}

.user-area .user-form-content .user-form .default-btn {
  width: 100%;
  margin-bottom: 30px;
}

.user-area .user-form-content .or {
  text-align: center;
  font-size: 16px;
  display: block;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
}

.user-area .user-form-content .or::before {
  content: "";
  position: absolute;
  top: 13px;
  left: 0;
  width: 45%;
  height: 1px;
  background-color: #cccccc;
  z-index: -1;
}

.user-area .user-form-content .or::after {
  content: "";
  position: absolute;
  top: 13px;
  right: 0;
  width: 45%;
  height: 1px;
  background-color: #cccccc;
  z-index: -1;
}

.user-area .user-form-content .or-login {
  display: block;
  text-align: center;
  border: 1px solid #cccccc;
  padding: 15px 30px;
  border-radius: var(--border-radius);
  margin-bottom: 30px;
  font-size: 16px;
}

.user-area .user-form-content .or-login i {
  color: #3b5998;
  font-size: 20px;
  position: relative;
  top: 3px;
}

.user-area .user-form-content .or-login.google i {
  color: #ed1d24;
}

.user-area .user-form-content .or-login:hover {
  background-color: #0286cd;
  color: var(--white-color);
  border-color: #0286cd;
}

.user-area .user-form-content .or-login:hover i {
  color: var(--white-color);
}

.user-area .user-form-content .create {
  text-align: center;
  font-size: 16px;
}

.user-area .user-form-content .create a {
  color: #0286cd;
}

.user-area .user-form-content.log-in-width {
  max-width: 930px;
  margin: auto;
}

.user-area .user-form-content.register-width {
  max-width: 930px;
  margin: auto;
}

.user-area #remember-2 {
  position: relative;
  top: 1px;
}

/*
Error Area CSS
=====================================================*/
.error-area {
  text-align: center;
  position: relative;
  z-index: 1;
  height: 100%;
  margin-bottom: -100px;
}

.error-area .error-content {
  padding-bottom: 138px;
  z-index: 1;
  position: relative;
}

.error-area .error-content h1 {
  font-size: 300px;
  line-height: 1;
  font-weight: 700;
  color: #0286cd;
}

.error-area .error-content h1 .a {
  display: inline-block;
}

.error-area .error-content h1 .red {
  color: #ff0000;
  display: inline-block;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.error-area .error-content h1 .b {
  display: inline-block;
}

.error-area .error-content h3 {
  margin: 30px 0 0;
  position: relative;
  color: #ff0000;
}

.error-area .error-content p {
  margin: 20px 0 20px;
  font-size: 19px;
  color: #0286cd;
}

/*
Coming Soon Area Style
=====================================================*/
.coming-soon-area {
  height: 100vh;
  position: relative;
}

.coming-soon-area .coming-soon-content {
  max-width: 800px;
  height: 800px;
  overflow: hidden;
  text-align: center;
  padding: 40px 60px;
  margin-left: auto;
  margin-right: auto;
  background-color: #eaeaea;
  position: relative;
  -webkit-clip-path: polygon(
    20% 0%,
    80% 0%,
    100% 20%,
    100% 80%,
    80% 100%,
    20% 100%,
    0% 80%,
    0% 20%
  );
  clip-path: polygon(
    20% 0%,
    80% 0%,
    100% 20%,
    100% 80%,
    80% 100%,
    20% 100%,
    0% 80%,
    0% 20%
  );
}

.coming-soon-area .coming-soon-content .logo {
  display: inline-block;
}

.coming-soon-area .coming-soon-content .circle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.coming-soon-area .coming-soon-content h2 {
  font-size: 48px;
  margin-top: -10px;
  margin-bottom: 0;
}

.coming-soon-area .coming-soon-content #timer {
  margin-top: 40px;
}

.coming-soon-area .coming-soon-content #timer div {
  border: 4px solid#0286CD;
  color: #0286cd;
  width: 100px;
  height: 105px;
  font-size: 40px;
  font-weight: 800;
  margin-left: 10px;
  margin-right: 10px;
}

.coming-soon-area .coming-soon-content #timer div span {
  display: block;
  margin-top: -2px;
  font-size: 17px;
  font-weight: 600;
}

.coming-soon-area .coming-soon-content form {
  position: relative;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.coming-soon-area .coming-soon-content form .form-group {
  margin-bottom: 25px;
  width: 100%;
  position: relative;
}

.coming-soon-area .coming-soon-content form .form-group .label-title {
  margin-bottom: 0;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  color: var(--heading-color);
}

.coming-soon-area .coming-soon-content form .form-group .label-title i {
  position: absolute;
  left: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  top: 9px;
  font-size: 22px;
}

.coming-soon-area .coming-soon-content form .form-group .label-title::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background: #0286cd;
}

.coming-soon-area .coming-soon-content form .form-group .input-newsletter {
  border-radius: 0;
  border: 1px solid #eeeeee;
  padding: 10px 20px;
  color: var(--heading-color);
  height: 50px;
  display: block;
  width: 100%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 16px;
  font-weight: 400;
  background-color: var(--white-color);
}

.coming-soon-area
  .coming-soon-content
  form
  .form-group
  .input-newsletter::-webkit-input-placeholder {
  color: var(--body-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.coming-soon-area
  .coming-soon-content
  form
  .form-group
  .input-newsletter:-ms-input-placeholder {
  color: var(--body-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.coming-soon-area
  .coming-soon-content
  form
  .form-group
  .input-newsletter::-ms-input-placeholder {
  color: var(--body-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.coming-soon-area
  .coming-soon-content
  form
  .form-group
  .input-newsletter::placeholder {
  color: var(--body-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.coming-soon-area
  .coming-soon-content
  form
  .form-group
  .input-newsletter:focus {
  border-color: #0286cd;
  outline: 0 !important;
}

.coming-soon-area
  .coming-soon-content
  form
  .form-group
  .input-newsletter:focus::-webkit-input-placeholder {
  color: transparent;
}

.coming-soon-area
  .coming-soon-content
  form
  .form-group
  .input-newsletter:focus:-ms-input-placeholder {
  color: transparent;
}

.coming-soon-area
  .coming-soon-content
  form
  .form-group
  .input-newsletter:focus::-ms-input-placeholder {
  color: transparent;
}

.coming-soon-area
  .coming-soon-content
  form
  .form-group
  .input-newsletter:focus::placeholder {
  color: transparent;
}

.coming-soon-area .coming-soon-content form .default-btn {
  border-radius: 0;
  position: relative;
  height: 46px;
  line-height: 0;
}

.coming-soon-area .coming-soon-content form .default-btn::before {
  border-radius: 0;
}

.coming-soon-area .coming-soon-content form .validation-danger {
  margin-top: 15px;
  color: #ff0000;
}

.coming-soon-area .coming-soon-content form .validation-success {
  margin-top: 15px;
}

.coming-soon-area .coming-soon-content form p {
  margin-bottom: 0;
  margin-top: 20px;
}

.coming-soon-area .coming-soon-content .newsletter-form {
  position: relative;
}

.coming-soon-area .coming-soon-content .newsletter-form .default-btn {
  position: absolute;
  top: 2px;
  right: 2px;
}

.coming-soon-area .online-treatment-shape-1 {
  position: absolute;
  top: 0;
  right: 0;
}

.coming-soon-area .online-treatment-shape-2 {
  position: absolute;
  bottom: 0;
  left: 0;
}

/*
Blog Details Content Style
=====================================================*/
.blog-details-content .blog-details-img {
  position: relative;
  margin-bottom: 30px;
}

.blog-details-content .blog-details-img .date {
  position: absolute;
  top: 30px;
  right: 30px;
  text-align: center;
  background-color: #0286cd;
  color: var(--white-color);
  padding: 20px 20px;
  font-size: 20px;
  font-weight: 600;
}

.blog-details-content .blog-top-content .product {
  color: #0286cd;
  font-size: 16px;
  display: block;
  margin-bottom: 10px;
}

.blog-details-content .blog-top-content h3 {
  font-size: 30px;
  margin-bottom: 20px;
}

.blog-details-content .blog-top-content .blog-content .admin {
  padding: 0;
  margin: 0;
  list-style-type: none;
  margin-bottom: 10px;
}

.blog-details-content .blog-top-content .blog-content .admin li {
  margin-right: 15px;
  padding-right: 15px;
  position: relative;
  display: inline-block;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 16px;
}

.blog-details-content .blog-top-content .blog-content .admin li::before {
  content: "";
  position: absolute;
  top: 6px;
  right: -5px;
  width: 1px;
  height: 60%;
  background-color: var(--body-color);
  -webkit-transform: rotate(20deg);
  transform: rotate(20deg);
}

.blog-details-content .blog-top-content .blog-content .admin li i {
  color: #0286cd;
  display: inline-block;
  margin-right: 5px;
  position: relative;
  top: 1px;
}

.blog-details-content .blog-top-content .blog-content .admin li:last-child {
  margin-right: 0;
  padding-right: 0;
}

.blog-details-content
  .blog-top-content
  .blog-content
  .admin
  li:last-child::before {
  display: none;
}

.blog-details-content .blog-top-content .blog-content .gap-mb-20 {
  margin-bottom: 20px;
}

.blog-details-content .blog-top-content .blog-content .admirer {
  background-color: #f3f3f4;
  padding: 30px 100px;
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
}

.blog-details-content .blog-top-content .blog-content .admirer img {
  margin-bottom: 15px;
  border-radius: 50%;
}

.blog-details-content .blog-top-content .blog-content .admirer span {
  font-size: 14px;
  margin-bottom: 10px;
  display: block;
  color: #0286cd;
}

.blog-details-content .blog-top-content .blog-content .admirer h3 {
  font-size: 20px;
}

.blog-details-content .blog-top-content .blog-content .admirer p {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}

.blog-details-content .blog-top-content blockquote {
  padding: 30px;
  position: relative;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f6f7ff),
    to(#f0f8ff)
  );
  background: linear-gradient(to right, #f6f7ff, #f0f8ff);
  border-left: 2px solid#0286CD;
  padding-left: 130px;
}

.blog-details-content .blog-top-content blockquote img {
  border-radius: 50px;
  position: absolute;
  top: 30px;
  left: 30px;
}

.blog-details-content .blog-top-content blockquote p {
  font-size: 18px;
  color: var(--heading-color);
  margin-bottom: 20px;
}

.blog-details-content .blog-top-content blockquote span {
  position: relative;
}

.blog-details-content .blog-top-content blockquote i {
  font-size: 60px;
  color: #0286cd;
  position: absolute;
  top: 25px;
  left: 0;
  line-height: 1;
}

.blog-details-content .tags {
  margin-top: 30px;
  margin-bottom: 40px;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding-top: 10px;
  padding-bottom: 10px;
}

.blog-details-content .tags .tag-link {
  padding: 0;
  margin: 0;
  list-style-type: none;
  line-height: 1;
  display: inline-block;
}

.blog-details-content .tags .tag-link li {
  display: inline-block;
  margin-right: 15px;
}

.blog-details-content .tags .tag-link li.title i {
  color: var(--heading-color);
  font-size: 20px;
  position: relative;
  top: 4px;
}

.blog-details-content .tags .tag-link li a {
  font-size: 15px;
  display: inline-block;
  border-radius: var(--border-radius);
}

.blog-details-content .tags .tag-link li a:hover {
  color: #0286cd;
}

.blog-details-content .tags .tag-link .title {
  font-weight: 600;
  font-size: 15px;
  color: var(--heading-color);
}

.blog-details-content .share-link {
  display: inline-block;
  float: right;
  position: relative;
  padding-bottom: 0;
}

.blog-details-content .share-link span {
  font-weight: 600;
  font-size: 14px;
  position: relative;
  top: 0;
  cursor: pointer;
}

.blog-details-content .share-link span i {
  font-size: 15px;
  background-color: #0286cd;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  color: var(--white-color);
  border-radius: 50px;
}

.blog-details-content .share-link .social-icon {
  padding: 0;
  margin: 0;
  list-style-type: none;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  z-index: 1;
}

.blog-details-content .share-link .social-icon li {
  display: inline-block;
  margin-right: 10px;
}

.blog-details-content .share-link .social-icon li:last-child {
  margin-right: 0;
}

.blog-details-content .share-link .social-icon li span {
  color: var(--heading-color);
  position: relative;
  top: -4px;
}

.blog-details-content .share-link .social-icon li a i {
  font-size: 18px;
  background-color: #bdbdbd;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50px;
  display: inline-block;
  text-align: center;
  color: var(--white-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.blog-details-content .share-link .social-icon li a:hover i {
  background-color: #0286cd;
}

.blog-details-content .comment {
  padding: 0;
  margin: 0;
  list-style-type: none;
  margin-bottom: 40px;
}

.blog-details-content .comment li {
  display: inline-block;
  position: relative;
  padding-left: 100px;
  margin-bottom: 40px;
}

.blog-details-content .comment li:last-child {
  margin-bottom: 0;
}

.blog-details-content .comment li img {
  position: absolute;
  top: 30px;
  left: 0;
}

.blog-details-content .comment li h3 {
  font-size: 18px;
  margin-top: -5px;
  margin-bottom: 2px;
}

.blog-details-content .comment li span {
  font-size: 14px;
  display: block;
  margin-bottom: 10px;
}

.blog-details-content .comment li p {
  margin-bottom: 0;
}

.blog-details-content .comment li .read-more {
  position: absolute;
  top: 0;
  right: 30px;
}

.blog-details-content .comment li.margin-left {
  margin-left: 30px;
}

.blog-details-content .leave-reply {
  background-color: var(--white-color);
}

.blog-details-content .leave-reply h3 {
  margin-bottom: 15px;
  font-size: 25px;
  position: relative;
}

.blog-details-content .leave-reply form p {
  margin-bottom: 30px;
}

.blog-details-content .leave-reply form .form-group {
  margin-bottom: 30px;
}

.blog-details-content .leave-reply form .form-group label {
  margin-bottom: 10px;
  font-weight: 500;
  color: #3e3e3e;
}

.blog-details-content .leave-reply form .form-group .form-control {
  background-color: var(--white-color);
  border: 1px solid #ebebeb;
}

.blog-details-content
  .leave-reply
  form
  .form-group
  .form-control::-webkit-input-placeholder {
  color: #cccccc;
}

.blog-details-content
  .leave-reply
  form
  .form-group
  .form-control:-ms-input-placeholder {
  color: #cccccc;
}

.blog-details-content
  .leave-reply
  form
  .form-group
  .form-control::-ms-input-placeholder {
  color: #cccccc;
}

.blog-details-content .leave-reply form .form-group .form-control::placeholder {
  color: #cccccc;
}

/*
Sidebar Widget Style
=====================================================*/
.sidebar-widget {
  margin-bottom: 35px;
  padding: 30px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f6f7ff),
    to(#f0f8ff)
  );
  background: linear-gradient(to right, #f6f7ff, #f0f8ff);
}

.sidebar-widget h3 {
  font-size: 22px;
  margin-bottom: 15px;
  padding-bottom: 8px;
  position: relative;
  border-bottom: 1px solid #eeeeee;
  padding-left: 10px;
}

.sidebar-widget h3::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background-color: #0286cd;
}

.sidebar-widget ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.sidebar-widget.search {
  padding: 0;
}

.sidebar-widget.search .search-form {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f6f7ff),
    to(#f0f8ff)
  );
  background: linear-gradient(to right, #f6f7ff, #f0f8ff);
  position: relative;
}

.sidebar-widget.search .search-form .form-control {
  border: none;
  height: 70px;
}

.sidebar-widget.search .search-form .search-button {
  position: absolute;
  width: 55px;
  height: 55px;
  line-height: 55px;
  top: 8px;
  right: 8px;
  font-size: 20px;
  color: var(--white-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: #0286cd;
}

.sidebar-widget.search .search-form .search-button:hover {
  background-color: var(--heading-color);
}

.sidebar-widget.categories ul li {
  position: relative;
  margin-bottom: 10px;
  font-size: 15px;
  position: relative;
  font-weight: 600;
}

.sidebar-widget.categories ul li a {
  display: block;
  color: var(--heading-color);
}

.sidebar-widget.categories ul li a i {
  font-size: 20px;
  position: relative;
  top: 4px;
}

.sidebar-widget.categories ul li a:hover {
  margin-left: 5px;
  color: #0286cd;
}

.sidebar-widget.categories ul li span {
  float: right;
  position: relative;
  top: 1px;
}

.sidebar-widget.categories ul li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.sidebar-widget.recent-post ul li {
  margin-bottom: 15px;
  padding-bottom: 20px;
  position: relative;
  padding-left: 90px;
  border-bottom: 1px solid #eeeeee;
}

.sidebar-widget.recent-post ul li:last-child {
  margin-bottom: 0;
  border-bottom: none;
  padding-bottom: 0;
}

.sidebar-widget.recent-post ul li a {
  font-size: 15px;
  color: var(--heading-color);
  margin-bottom: 10px;
  font-weight: 600;
  display: inline-block;
}

.sidebar-widget.recent-post ul li a:hover {
  color: #0286cd;
}

.sidebar-widget.recent-post ul li a img {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 80px;
  height: 80px;
}

.sidebar-widget.recent-post ul li span {
  display: block;
  font-size: 14px;
}

.sidebar-widget.tags ul li {
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 10px;
}

.sidebar-widget.tags ul li a {
  border: 1px solid #eeeeee;
  padding: 8px 12px;
  display: inline-block;
  background-color: var(--white-color);
}

.sidebar-widget.tags ul li a:hover {
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
  background-color: #0286cd;
  color: var(--white-color);
  border-color: #0286cd;
}

.sidebar-widget.tags ul li:last-child {
  margin-bottom: 0;
}

.sidebar-widget .map iframe {
  width: 100%;
  height: 300px;
}

/*
Event Details Content Style
=====================================================*/
.event-details-content .event-details-img {
  position: relative;
  margin-bottom: 25px;
}

.event-details-content .event-details-img .date {
  position: absolute;
  top: 30px;
  right: 30px;
  text-align: center;
  background-color: #0286cd;
  color: var(--white-color);
  padding: 20px 20px;
  font-size: 20px;
  font-weight: 600;
}

.event-details-content .event-top-content h3 {
  font-size: 25px;
  margin-bottom: 20px;
}

.event-details-content .event-top-content .event-content .admin {
  padding: 0;
  margin: 0;
  list-style-type: none;
  margin-bottom: 15px;
}

.event-details-content .event-top-content .event-content .admin li {
  position: relative;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 16px;
  margin-bottom: 10px;
}

.event-details-content .event-top-content .event-content .admin li i {
  color: #0286cd;
  display: inline-block;
  margin-right: 5px;
  position: relative;
  top: 1px;
}

.event-details-content .event-top-content .event-content .admin li:last-child {
  margin-bottom: 0;
}

.event-details-content .event-top-content .event-content .gap-mb-20 {
  margin-bottom: 20px;
}

.single-speakers {
  margin-bottom: 30px;
}

.single-speakers .speaker-title {
  text-align: center;
  background-color: var(--white-color);
  -webkit-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
  padding: 15px 20px;
}

.single-speakers .speaker-title h4 {
  font-size: 18px;
  margin-bottom: 5px;
}

.single-speakers .speaker-title span {
  font-size: 15px;
}

.choose-us-from-bg h2 {
  font-size: 40px;
  color: var(--white-color);
  margin-bottom: 30px;
  text-align: center;
  margin-right: -9px;
}

.choose-us-from-bg label {
  margin-bottom: 12px;
  color: var(--heading-color);
  display: block;
}

.choose-us-from-bg .form-group {
  margin-bottom: 25px;
  position: relative;
}

.choose-us-from-bg .form-group .form-control {
  color: var(--heading-color);
  background-color: #fff;
}

.choose-us-from-bg .form-group .form-control::-webkit-input-placeholder {
  color: var(--body-color);
  font-size: 15px;
}

.choose-us-from-bg .form-group .form-control:-ms-input-placeholder {
  color: var(--body-color);
  font-size: 15px;
}

.choose-us-from-bg .form-group .form-control::-ms-input-placeholder {
  color: var(--body-color);
  font-size: 15px;
}

.choose-us-from-bg .form-group .form-control::placeholder {
  color: var(--body-color);
  font-size: 15px;
}

.choose-us-from-bg .form-group .form-control:focus {
  border-color: #0286cd;
}

.choose-us-from-bg .default-btn {
  width: 100%;
}

/*
Causes Details Content Style
=====================================================*/
.causes-details-content .event-details-img {
  margin-bottom: 30px;
}

.causes-details-content .donation-wrap h4 {
  font-size: 18px;
  margin-bottom: 10px;
}

.causes-details-content .donation-wrap ul {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-bottom: 20px;
}

.causes-details-content .donation-wrap ul li {
  display: inline-block;
  margin-right: 30px;
}

.causes-details-content .donation-wrap ul li:last-child {
  margin-right: 0;
}

.causes-details-content .donation-wrap .mb-10 {
  margin-bottom: 15px;
}

.causes-details-content .donation-wrap .form-group {
  position: relative;
}

.causes-details-content .donation-wrap .form-group .default-btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 55px;
}

.causes-details-content .single-causes h3 {
  font-size: 30px;
}

.causes-details-content .single-causes .bar-percent {
  right: 0;
}

.causes-details-content .single-causes:hover {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.causes-details-content .single-causes .bar-1 {
  margin-top: 0;
  margin-bottom: 0;
}

.causes-details-content .single-causess {
  margin-bottom: 20px;
}

.causes-details-content .single-causes .bar::before {
  background-color: var(--white-color);
}

.causes-details-content .causes-content h1,
.causes-details-content .causes-content h2,
.causes-details-content .causes-content h3,
.causes-details-content .causes-content h4,
.causes-details-content .causes-content h5,
.causes-details-content .causes-content h6 {
  margin-bottom: 20px;
}

.widget-sidebar .owl-theme .owl-nav {
  margin-top: 0;
  position: absolute;
  top: -52px;
  right: 0;
}

.widget-sidebar .owl-theme .owl-nav .owl-prev,
.widget-sidebar .owl-theme .owl-nav .owl-next {
  background-color: transparent;
}

.widget-sidebar .owl-theme .owl-nav .owl-prev i,
.widget-sidebar .owl-theme .owl-nav .owl-next i {
  font-size: 20px;
}

.widget-sidebar .owl-theme .owl-nav .owl-prev i:hover,
.widget-sidebar .owl-theme .owl-nav .owl-next i:hover {
  color: #0286cd;
}

.widget-sidebar .single-causes {
  margin-bottom: 0;
}

.widget-sidebar .single-causes h3 {
  padding-left: 0;
  border-bottom: none;
  padding-bottom: 0;
  font-size: 20px;
}

.widget-sidebar .single-causes h3::before {
  display: none;
}

.widget-sidebar .single-causes .bar-1,
.widget-sidebar .single-causes .my-skills {
  margin-bottom: 0;
}

/*====================================================
OTHERS STYLE AREA
=====================================================*/
/*
Preloader Area Style*/
.preloader {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #0286cd;
  top: 0;
  left: 0;
  z-index: 99999;
}

.preloader .lds-ripple {
  position: relative;
  width: 55px;
  height: 55px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.preloader .lds-ripple div {
  position: absolute;
  border: 2px dashed #ffffff;
  opacity: 1;
  -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.preloader .lds-ripple div:nth-child(2) {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.preloader::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 60%;
  z-index: -1;
  background: #0286cd;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.preloader::after {
  left: auto;
  right: 0;
}

.preloader.preloader-deactivate {
  visibility: hidden;
}

.preloader.preloader-deactivate::after {
  width: 0;
}

.preloader.preloader-deactivate::before {
  width: 0;
}

.preloader.preloader-deactivate .loader {
  opacity: 0;
  visibility: hidden;
}

@-webkit-keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

/*
Go Top Style*/
.go-top {
  position: fixed;
  cursor: pointer;
  right: 3%;
  top: 87%;
  background-color: #F08821;
  z-index: 4;
  width: 40px;
  text-align: center;
  height: 42px;
  line-height: 42px;
  -webkit-transform: scale(0);
  transform: scale(0);
  visibility: hidden;
  -webkit-transition: 0.9s;
  transition: 0.9s;
}

.go-top i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  color: var(--white-color);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 20px;
}

.go-top i:last-child {
  opacity: 0;
  visibility: hidden;
  top: 60%;
}

.go-top::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #F08821;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.go-top:hover {
  color: var(--white-color);
  background-color: #0286cd;
}

.go-top:hover::before {
  opacity: 1;
  visibility: visible;
}

.go-top:hover i:first-child {
  opacity: 0;
  top: 0;
  visibility: hidden;
}

.go-top:hover i:last-child {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

.go-top:focus {
  color: var(--white-color);
}

.go-top:focus::before {
  opacity: 1;
  visibility: visible;
}

.go-top:focus i:first-child {
  opacity: 0;
  top: 0;
  visibility: hidden;
}

.go-top:focus i:last-child {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

.go-top.active {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  right: 4%;
  top: 84%;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.go-top.style-three {
  background-color: #0286cd;
}

/*
Video wave Style*/
.video-btn {
  display: inline-block;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border-radius: 0;
  color: #0286cd;
  position: relative;
  top: 3px;
  z-index: 1;
  background-color: rgba(247, 148, 29, 0.9);
  border-radius: 50%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.video-btn i {
  font-size: 60px;
  color: var(--white-color);
  position: absolute;
  top: 0;
  left: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
}

.video-btn::after,
.video-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 0;
  border-radius: 50%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -webkit-animation: ripple 1.6s ease-out infinite;
  animation: ripple 1.6s ease-out infinite;
  background-color: rgba(247, 148, 29, 0.9);
}

.video-btn:hover {
  background-color: rgba(27, 27, 27, 0.9);
}

.video-btn:hover::before,
.video-btn:hover::after {
  background-color: rgba(27, 27, 27, 0.9);
}

@-webkit-keyframes ripple {
  0%,
  35% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }

  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0.8;
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(2);
    transform: scale(2);
  }
}

@keyframes ripple {
  0%,
  35% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }

  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0.8;
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(2);
    transform: scale(2);
  }
}

/*
Page-navigation Area Style*/
.pagination-area {
  text-align: center;
  margin-top: 10px;
}

.pagination-area .page-numbers {
  width: 45px;
  height: 45px;
  line-height: 45px;
  color: var(--heading-color);
  text-align: center;
  display: inline-block;
  position: relative;
  margin-left: 3px;
  margin-right: 3px;
  font-size: 17px;
  background-color: #f9fbfe;
  -webkit-box-shadow: var(--box-shadow);
  box-shadow: var(--box-shadow);
  border: 1px solid #ebebeb;
}

.pagination-area .page-numbers:hover {
  color: var(--white-color);
  border-color: #0286cd;
  background-color: #0286cd;
}

.pagination-area .page-numbers i {
  font-size: 20px;
}

.pagination-area .page-numbers.next,
.pagination-area .page-numbers.prev {
  top: 3px;
}

.pagination-area .page-numbers.current {
  color: var(--white-color);
  border-color: #0286cd;
  background-color: #0286cd;
}

.main-default-content h3 {
  margin-bottom: 15px;
  font-size: 25px;
}

/*# sourceMappingURL=style.css.map */
@media only screen and (min-width: 1650px) and (max-width: 3000px) {
  .banner-area .banner-img-2 {
    right: 223px !important;
    max-width: 529px !important;
  }
}

/*=============== Recent Causes ===================*/
.title {
  text-align: center;
  margin: 0 0 60px;
}

.title.left {
  text-align: left;
}

.title span {
  font-size: 14px;
  color: #718289;
  text-transform: uppercase;
  margin: 0 0 10px;
  display: inline-block;
}

.title h2 {
  font-size: 35px;
  color: #2f2f2f;
  text-transform: uppercase;
  margin: 0;
  line-height: 1.1;
  font-weight: 600;
  font-family: roboto;
}

.title h2 i {
  /* font-style: normal; */
  font-weight: 600;
  font-family: roboto;
}

.title.style2 span {
  font-size: 15px;
  color: #212121;
  font-weight: 500;
  text-transform: capitalize;
}

.title.style2 h2 i {
  color: #2f2f2f;
}

.title p {
  margin: 20px 0 30px;
}

.button-set .btn {
  display: inline-block;
  margin: 0 20px 0 0;
}

.button-set .btn:last-child {
  margin: 0;
}

/*=============== Recent Causes ===================*/
.recent-causes {
  float: left;
  width: 100%;
  /* padding:70px 0; */
  position: relative;
}

.recent-causes:before {
  content: "";
  position: absolute;
  left: 160px;
  top: 0;
  /* width:calc(100% + 1000px); */
  /* width:calc(100%); */
  height: 100%;
  background: #f8f8f8;
}

.cause-img {
  float: left;
  width: 100%;
  position: relative;
}

.cause-bg {
  float: left;
  width: 68%;
  position: relative;
}

.cause-bg img {
  width: 100%;
}

.cause-bg:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #2f2f2f;
  opacity: 0.8;
}

.cause-inner {
  position: absolute;
  right: 0;
  top: 50%;
  border: 10px solid #d9a2a5;
  width: 40%;
  -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  opacity: 0;
  -webkit-transform: translateY(-50%) translateX(30px);
  -moz-transform: translateY(-50%) translateX(30px);
  -ms-transform: translateY(-50%) translateX(30px);
  -o-transform: translateY(-50%) translateX(30px);
  transform: translateY(-50%) translateX(30px);
  -webkit-transition: all 0.3s ease 0.2s;
  -moz-transition: all 0.3s ease 0.2s;
  -ms-transition: all 0.3s ease 0.2s;
  -o-transition: all 0.3s ease 0.2s;
  transition: all 0.3s ease 0.2s;
}

.slick-active .cause-inner {
  opacity: 1;
  -webkit-transform: translateY(-50%) translateX(0);
  -moz-transform: translateY(-50%) translateX(0);
  -ms-transform: translateY(-50%) translateX(0);
  -o-transform: translateY(-50%) translateX(0);
  transform: translateY(-50%) translateX(0);
}

.cause-text {
  position: absolute;
  left: 0;
  bottom: 40px;
  transform: translateX(-100%);
  padding-right: 80px;
  width: 150%;
}

.cause-text h4 {
  float: left;
  width: 100%;
  line-height: 1;
  margin: 0;
  text-align: right;
}

.cause-text span {
  font-size: 14px;
  color: #fff;
  float: right;
  line-height: 1;
  padding-bottom: 10px;
  position: relative;
  margin: 0 0 5px;
  opacity: 0;
  -webkit-transform: translateX(-30px);
  -moz-transform: translateX(-30px);
  -ms-transform: translateX(-30px);
  -o-transform: translateX(-30px);
  transform: translateX(-30px);
  -webkit-transition: all 0.5s ease 0.3s;
  -moz-transition: all 0.5s ease 0.3s;
  -ms-transition: all 0.5s ease 0.3s;
  -o-transition: all 0.5s ease 0.3s;
  transition: all 0.5s ease 0.3s;
}

.cause-text span:before {
  content: "";
  width: 0;
  height: 1px;
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: all 0.5s ease 0.3s;
  -moz-transition: all 0.5s ease 0.3s;
  -ms-transition: all 0.5s ease 0.3s;
  -o-transition: all 0.5s ease 0.3s;
  transition: all 0.5s ease 0.3s;
}

.slick-active .cause-text span:before {
  width: calc(100% + 120px);
}

.cause-text h4 {
  font-size: 26px;
  color: #fff;
  font-weight: 200;
  opacity: 0;
  -webkit-transform: translateX(30px);
  -moz-transform: translateX(30px);
  -ms-transform: translateX(30px);
  -o-transform: translateX(30px);
  transform: translateX(30px);
  -webkit-transition: all 0.6s ease 0.5s;
  -moz-transition: all 0.6s ease 0.5s;
  -ms-transition: all 0.6s ease 0.5s;
  -o-transition: all 0.6s ease 0.5s;
  transition: all 0.6s ease 0.5s;
}

.cause-text h4 i {
  font-weight: 600;
  /* font-style: normal; */
  font-family: roboto;
}

.cause-inner p {
  clear: right;
  margin: 20px 0;
  display: inline-block;
  text-align: right;
  color: #fff;
}

.btn {
  float: none;
  display: inline-block;
  font-size: 16px;
  color: #efefef;
  padding: 13px 26px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}

.btn:hover {
  color: #fff;
  background: #ed15638a;
}

.btn.small {
  font-size: 13px;
  padding: 10px 20px;
  border: 2px solid #ed15638a;
}

.btn.style2 {
  color: #fff;
  border: 2px solid #fff;
  background: none;
}

.btn.style3 {
  color: #fff;
  background: #2e2e2e;
}

.btn.style4 {
  color: #fff;
  border: 2px solid #fff;
  background: none;
}

.btn.round {
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  border-radius: 50px;
}

.cause-inner .btn {
  float: right;
}

.slick-active .cause-text span,
.slick-active .cause-text h4 {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.creative-navs.slick-slider {
  position: absolute !important;
  width: 90px;
  left: 0;
  top: 50%;
  font-weight: 900;
  display: unset !important;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.creative-navs span {
  float: left;
  width: 100%;
  padding: 10px 70px 10px 0;
  text-align: center;
  position: relative;
  cursor: pointer;
}

.creative-navs span:before {
  content: "";
  position: absolute;
  right: 0;
  width: 0;
  top: 50%;
  height: 1px;
  /* background: #333; */
}

.creative-navs span.slick-current:before {
  width: 50px;
}

span.slick-current:before {
  width: 50px;
}

/*=============== Find Shelter ===================*/
.find-shelter {
  float: left;
  width: 100%;
  text-align: center;
  padding: 0 30px;
}

.find-shelter h3 {
  font-size: 24px;
  color: #fff;
  font-weight: 300;
  padding: 0 150px;
  line-height: 49px;
  margin: 0 0 40px;
}

.find-shelter .find-form {
  display: table;
}

.zip-find {
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
}

.zip-find input {
  display: inline-block;
  width: 270px;
  vertical-align: middle;
  height: 44px;
  font-size: 15px;
  color: #fff;
  margin: 0 20px 0 0;
  background: rgba(228, 228, 228, 0.2);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}

.zip-find button {
  display: inline-block;
  vertical-align: middle;
  height: 44px;
}

.zip-find button:hover {
  background: #fff;
  color: #333;
}

.find-shelter span {
  font-size: 13px;
  color: #efefef;
  padding: 0px 20px;
  display: table-cell;
  vertical-align: middle;
}

.find-shelter select {
  display: table-cell;
  vertical-align: middle;
  width: 400px;
  height: 43px;
  font-size: 15px;
  background: rgba(228, 228, 228, 0.2);
  border: 0;
  color: rgba(255, 255, 255, 0.6);
  padding: 0 20px;
}

.find-shelter select option {
  color: #333;
}

.find-shelter.style2 {
  padding: 0;
  text-align: left;
  margin-top: -30px;
}

.find-shelter.style2 h3 {
  padding: 0;
  font-size: 15px;
  color: #4c4c4c;
  line-height: 1.9;
  margin: 0 0 30px;
}

.find-shelter.style2 .find-form label {
  float: left;
  width: 100%;
  font-size: 13px;
  color: #5c5c5c;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 0 6px;
}

.find-shelter.style2 .find-form {
  display: unset;
  float: left;
  width: 100%;
}

.find-shelter.style2 .zip-find input {
  background: #2e2e2e;
  margin-right: 10px;
  width: 220px;
}

.find-shelter.style2 .find-form .zip-find {
  float: left;
  width: 100%;
  margin: 0 0 40px;
}

.find-shelter.style2 select {
  background: #2e2e2e;
  display: unset;
  width: 100%;
}

/*=============== Shelter Texts ===================*/
.shelter-text > span {
  font-size: 15px;
  display: block;
  color: #f08821;
  margin: 0 0 5px;
}

.shelter-text h3 {
  font-size: 35px;
  font-weight: 200;
  color: #fff;;
  text-transform: uppercase;
  margin: 0 0 15px;
}

.shelter-text h3 span {
  font-weight: 600;
}

.shelter-text p {
  margin: 0 0 35px;
  color: #fff;
}

/*=============== Stories Carousel ===================*/
.stories-carousel {
  float: left;
  width: 100%;
}

/* .story-slide {
  padding: 0 8px
} */

.story {
  float: left;
  width: 100%;
  position: relative;
  border: 2px solid #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
  margin: 3px 0;
}

.story img {
  width: 100%;
}

.story h3 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  font-size: 15px;
  text-align: center;
  color: #fff;
  font-weight: 500;
  margin: 0;
  padding: 20px 25px;
  background: rgb(22 118 170 / 58%);
  font-weight: 400;
  line-height: 1.8;
}

.slick-dots {
  float: left;
  width: 100%;
  margin: 15px 0 0;
  text-align: center;
}

.slick-dots li {
  display: inline-block;
  padding: 0;
  margin: 0 2px;
}

.slick-dots li:before {
  display: none;
}

.slick-dots li button {
  padding: 0;
  color: transparent;
  width: 15px;
  height: 3px;
  background: #b2b2b2;
}

/*=============== Shelter Events ===================*/

/* ===============================================================
    Slick Carousel Styling
================================================================*/
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: inline !important;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
  padding: 5px;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* ===============================================================
    Simple Light Box
================================================================*/
.slbOverlay,
.slbWrapOuter,
.slbWrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.slbOverlay {
  overflow: hidden;
  background-color: #000;
  opacity: 0.7;
  filter: alpha(opacity=70);
  -webkit-animation: slbOverlay 0.5s;
  -moz-animation: slbOverlay 0.5s;
  animation: slbOverlay 0.5s;
  z-index: 1000;
}

.slbWrapOuter {
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 2010;
}

.slbWrap {
  position: absolute;
  text-align: center;
}

.slbWrap:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.slbContentOuter {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0px auto;
  padding: 0 1em;
  box-sizing: border-box;
  z-index: 2020;
  text-align: left;
  max-width: 100%;
}

.slbContentEl .slbContentOuter {
  padding: 5em 1em;
}

.slbContent {
  position: relative;
}

.slbContentEl .slbContent {
  -webkit-animation: slbEnter 0.3s;
  -moz-animation: slbEnter 0.3s;
  animation: slbEnter 0.3s;
  background-color: #fff;
  box-shadow: 0 0.2em 1em rgba(0, 0, 0, 0.4);
}

.slbImageWrap {
  -webkit-animation: slbEnter 0.3s;
  -moz-animation: slbEnter 0.3s;
  animation: slbEnter 0.3s;
  position: relative;
}

.slbImageWrap:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 5em;
  bottom: 5em;
  display: block;
  z-index: -1;
  box-shadow: 0 0.2em 1em rgba(0, 0, 0, 0.6);
  background-color: #fff;
}

.slbImage {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 5em 0;
  margin: 0 auto;
}

.slbCaption {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  font-size: 1.4em;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.71429em 0;
  color: #fff;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
}

.slbCloseBtn,
.slbArrow {
  margin: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
  background: none;
}

.slbCloseBtn:-moz-focus-inner,
.slbArrow:-moz-focus-inner {
  padding: 0;
  border: 0;
}

.slbCloseBtn:hover,
.slbArrow:hover {
  opacity: 0.5;
}

.slbCloseBtn:active,
.slbArrow:active {
  opacity: 0.8;
}

.slbCloseBtn {
  -webkit-animation: slbEnter 0.3s;
  -moz-animation: slbEnter 0.3s;
  animation: slbEnter 0.3s;
  font-size: 3em;
  width: 1.66667em;
  height: 1.66667em;
  line-height: 1.66667em;
  position: absolute;
  right: -0.33333em;
  top: 0;
  color: #fff;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
}

.slbLoading .slbCloseBtn {
  display: none;
}

.slbLoadingText {
  font-size: 1.4em;
  color: #fff;
  color: rgba(255, 255, 255, 0.9);
}

.slbArrows {
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
}

.slbLoading .slbArrows {
  display: none;
}

.slbArrow {
  position: absolute;
  top: 50%;
  margin-top: -5em;
  width: 5em;
  height: 10em;
  opacity: 0.7;
  text-indent: -999em;
  overflow: hidden;
}

.slbArrow:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -0.8em 0 0 -0.8em;
  border: 0.8em solid transparent;
}

.slbArrow.next {
  right: 0;
}

.slbArrow.next:before {
  border-left-color: #fff;
}

.slbArrow.prev {
  left: 0;
}

.slbArrow.prev:before {
  border-right-color: #fff;
}

.slbIframeCont {
  width: 80em;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
  margin: 5em 0;
}

.slbIframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0.2em 1em rgba(0, 0, 0, 0.6);
  background: #000;
}

@-webkit-keyframes slbOverlay {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.7;
  }
}

@-moz-keyframes slbOverlay {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.7;
  }
}

@keyframes slbOverlay {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.7;
  }
}

@-webkit-keyframes slbEnter {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -1em, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
  }
}

@-moz-keyframes slbEnter {
  from {
    opacity: 0;
    -moz-transform: translate3d(0, -1em, 0);
  }

  to {
    opacity: 1;
    -moz-transform: translate3d(0, 0, 0);
  }
}

@keyframes slbEnter {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -1em, 0);
    -moz-transform: translate3d(0, -1em, 0);
    -ms-transform: translate3d(0, -1em, 0);
    -o-transform: translate3d(0, -1em, 0);
    transform: translate3d(0, -1em, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

/* ===============================================================
    SELECT2 PLUGIN STYLING
================================================================*/
.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
  z-index: 9999999;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container[dir="rtl"]
  .select2-selection--single
  .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-search--inline {
  float: left;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
}

.select2-container
  .select2-search--inline
  .select2-search__field:-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 10px 20px;
  font-size: 12px;
}

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field:-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-container--default .select2-selection--single {
  background-color: #f2f2f2;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #444;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: #999;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  position: absolute;
  top: 1px;
  right: 1px;
  width: 40px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default[dir="rtl"]
  .select2-selection--single
  .select2-selection__clear {
  float: left;
}

.select2-container--default[dir="rtl"]
  .select2-selection--single
  .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled
  .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled
  .select2-selection--single
  .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove:hover {
  color: #333;
}

.select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice,
.select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__placeholder {
  float: right;
}

.select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--default.select2-container--disabled
  .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled
  .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above
  .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above
  .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below
  .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below
  .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option[aria-disabled="true"] {
  color: #999;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: #ddd;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__group {
  padding-left: 0;
}

.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f6f6f6;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, #ffffff 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, #ffffff 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, #ffffff 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#eeeeee', GradientType=0);
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic
  .select2-selection--single
  .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic
  .select2-selection--single
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}

.select2-container--classic
  .select2-selection--single
  .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic
  .select2-selection--single
  .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#cccccc', GradientType=0);
}

.select2-container--classic
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--classic[dir="rtl"]
  .select2-selection--single
  .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir="rtl"]
  .select2-selection--single
  .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open
  .select2-selection--single
  .select2-selection__arrow {
  background: transparent;
  border: none;
}

.select2-container--classic.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above
  .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #ffffff 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, #ffffff 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, #ffffff 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#eeeeee', GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below
  .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #ffffff 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #ffffff 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #ffffff 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#ffffff', GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic
  .select2-selection--multiple
  .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px;
}

.select2-container--classic
  .select2-selection--multiple
  .select2-selection__clear {
  display: none;
}

.select2-container--classic
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--classic
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--classic
  .select2-selection--multiple
  .select2-selection__choice__remove:hover {
  color: #555;
}

.select2-container--classic[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice {
  float: right;
}

.select2-container--classic[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--classic.select2-container--open
  .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above
  .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below
  .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
}

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option[aria-disabled="true"] {
  color: grey;
}

.select2-container--classic
  .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

.select2-container {
  float: left;
  width: 100% !important;
  margin: 0 0 10px;
}

.review {
  display: table;
  width: 100%;
  position: relative;
  padding-left: 12px;
  padding-top: 12px;
}

.review-img {
  float: left;
  background: #f8f8f8;
  border: 10px solid #fff;
  background: none;
  margin-right: 20px;
  margin-bottom: 30px;
  position: relative;
  -webkit-box-shadow: 0 0 14px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 0 0 14px rgba(0, 0, 0, 0.21);
  -ms-box-shadow: 0 0 14px rgba(0, 0, 0, 0.21);
  -o-box-shadow: 0 0 14px rgba(0, 0, 0, 0.21);
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.21);
}

.review-img img {
  max-width: none;
  position: relative;
}

.review-text {
  overflow: hidden;
}

.review-text h4 {
  float: left;
  width: 100%;
  font-size: 18px;
  color: #303035;
  line-height: 1.2;
  margin: 20px 0 20px;
}

.review-text h4 i {
  font-size: 12px;
  /* font-style: normal; */
  font-family: roboto;
  font-weight: 500;
}

.review-text p {
  font-size: 14px;
  line-height: 2;
  margin: 0;
  color: #646464;
}

.slick-dots {
  float: left;
  width: 100%;
  margin: 30px 0 0;
}

.slick-dots li {
  float: right;
  padding: 0;
  margin: 0;
}

.slick-dots li:before {
  display: none;
}

.slick-dots li button {
  padding: 0;
  color: transparent;
  height: 3px;
  width: 15px;
  margin-left: 4px;
  background: #5e5e5e;
  border-radius: 0;
}

.slick-dots li.active button {
  width: 30px;
}

.slick-dots li.slick-active button {
  width: 22px;
}

.review-img:before,
.review-img:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -20px;
  width: 30px;
  height: 30px;
  background: #fff;
  margin-left: -15px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.review-img:after {
  -webkit-box-shadow: -5px 5px 14px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: -5px 5px 14px rgba(0, 0, 0, 0.11);
  -ms-box-shadow: -5px 5px 14px rgba(0, 0, 0, 0.11);
  -o-box-shadow: -5px 5px 14px rgba(0, 0, 0, 0.11);
  box-shadow: -5px 5px 14px rgba(0, 0, 0, 0.11);
  z-index: -1;
}

.child-portfolio .slick-arrow {
  position: absolute;
  top: 50%;
  margin: -20px 0px 0px 0px;
  z-index: 10;
  font-size: 0;
  width: 30px;
  height: 30px;
}

.child-portfolio .slick-arrow.slick-prev {
  left: -150px;
  background: url("https://img.icons8.com/ios/50/000000/long-arrow-left.png") 0
    0 / 100% no-repeat;
}

.child-portfolio .slick-arrow.slick-next {
  right: -150px;
  background: url("https://img.icons8.com/ios/50/000000/long-arrow-right.png") 0
    0 / 100% no-repeat;
}

.logo-sliders .slick-arrow {
  position: absolute;
  top: 50%;
  margin: -20px 0px 0px 0px;
  z-index: 10;
  font-size: 0;
  width: 30px;
  height: 30px;
}

.logo-sliders .slick-arrow.slick-prev {
  left: -150px;
  background: url("https://img.icons8.com/ios/50/000000/long-arrow-left.png") 0
    0 / 100% no-repeat;
}

.logo-sliders .slick-arrow.slick-next {
  right: -150px;
  background: url("https://img.icons8.com/ios/50/000000/long-arrow-right.png") 0
    0 / 100% no-repeat;
}

@media only screen and (max-width: 767px) {
  .logo-sliders .slick-arrow.slick-next {
    right: -150px;
    background: url(https://img.icons8.com/ios/50/000000/long-arrow-right.png) 0
      0 / 100% no-repeat;
    display: none !important;
  }
  .navbar-collapse {
    margin-top: -7px;

    padding-top: 40px;
    padding-bottom: 100px;
  }
  .navbar-nav {
    font-size: 20px;
  }

  .page-title-area {
    padding-top: 150px;
    padding-bottom: 0;
    position: relative;
    z-index: 1;
    background-image: url('../../assets/images/page-bg/aboutus-ban.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
}

}

@media only screen and (max-width: 767px) {
  .adds-size {
    width: 100px;
    height: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title h2 {
    font-size: 18px !important;
  }

  .section-title p {
    font-size: 7px;
  }
}

@media only screen and (max-width: 767px) {
  .height-bb {
    height: 150px !important;
  }
}

@media only screen and (max-width: 767px) {
  .height-bbb {
    height: 150px !important;
    padding: 0px !important;
  }
}

@media only screen and (max-width: 767px) {
  .height-rrr {
    margin-top: 50px !important;
  }
}

@media only screen and (max-width: 767px) {
  .height-rr {
    margin-top: 25px !important;
  }
}

.cause-text i {
  font-size: 26px;
  color: #0286cd;
  text-shadow: 0 0 3px #ffffff;
}

.cause-text h4 {
  text-shadow: 0 0 3px #0286cd;
}

@media screen and (max-width: 600px) {
  .about-area .about-style-three .ptb-100 {
    margin-top: 500px !important;
  }
}

@media screen and (max-width: 600px) {
  /* .about-area  {
    margin-top: 500px  !important;
  } */
}

@media screen and (max-width: 600px) {
  /* .float-sm {
    display: none;
  } */

  .call-wrap {
    display: none;
  }

  /* .story-slide-img {
    height: 430px;
  } */
}

@media screen and (max-width: 600px) {
  .mb-gallery {
    display: none;
  }

  /*.obu{*/
  /*  float:left;*/
  /*}*/
}

@media only screen and (min-width: 1200px) {
  #redessociales {
    display: none !important;
  }
}

@media screen and (max-width: 900px) {
  .mb-gallery {
    display: none;
  }
  .float-sm {
    display: none;
  }

  .obu {
    flex-direction: column-reverse;
  }

}

@media screen and (min-width: 900px) {
  .share_content {
    display: none !important;
  }
 
}

@media screen and (max-width: 1200px) {
  .mb-gallery {
    display: none;
  }

 

  .logo-sliders .slick-arrow.slick-prev {
    display: none !important;
  }

  .logo-sliders .slick-arrow.slick-next {
    display: none !important;
  }

  .child-portfolio .slick-arrow.slick-prev {
    display: none !important;
  }

  .child-portfolio .slick-arrow.slick-next {
    display: none !important;
  }
}

@media only screen and (min-width: 1200px) {
  .gallery-bs {
    display: none !important;
  }

  .big-hide {
    display: none !important;
  }
}

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

@media screen and (max-width: 900px) {
  .page-title-area .page-title-content {
    margin-right: 0px !important;
  }

  .edu-flip {
    display: none !important;
  }

  .page-title-area::before {
    background-image: url(../img/akoda-mbi.PNG) !important;
  }

  .page-title-area::before {
    background-color: #7e76760a !important;
  }
}

/*---------- anmation mixin load ----------*/
.assistiveItems ul {
  padding: 0;
  margin: 0;
  position: relative;
  list-style: none;
}

.wrapperAssistive {
  position: fixed;
  z-index: 2;
  display: block;
  width: 70px;
  height: 70px;
  /* left: calc(50% - 35px); */
  top: calc(calc(50% - 35px) - 130px);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /*-webkit-filter: url("#goo");*/
  /*filter: url("#goo");*/
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  /*transform:  rotate(-110deg)!important;*/
}

.assistiveTouch {
  position: relative;
  z-index: 2;
  background: #e2cee3;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.assistiveTouch .box {
  background: #ee2e7ab8;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.assistiveTouch .box,
.assistiveTouch img {
  width: 80%;
  height: 80%;
  display: block;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -user-drag: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.assistiveItems {
  position: absolute;
  z-index: 1;
  display: block;
  width: 80%;
  height: 80%;
  left: 50%;
  top: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.assistiveItems ul {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.assistiveItems ul li {
  margin: 0;
  padding: 0;
}

.atItem {
  position: absolute;
  z-index: 2;
  display: block;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0;
}

.atItem a:hover {
  color: white;
}

.btnAtItem {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #fff;
  border: none;
  outline: none;
  color: white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.atItemBg {
  position: absolute;
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #ff9800;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.overlayAssistive {
  position: fixed;
  z-index: 1;
  display: none;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.7;
  top: 0;
  left: 0;
}
@media screen and (max-width: 700px) {
  .form-control {
    height: 40px;
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .banner-content .top-title {
    padding: 0px !important;
  }

  .blog-post-area {
    display: none !important;
  }

  .page-title-area .page-title-content {
    padding-bottom: 0px !important;
    top: -58px;
  }

  .volunteers-area {
    margin-top: 30px;
  }

  .block-mobi {
    /* display: none!important; */
    margin-top: -70px;
  }

  .shelter-text h3 {
    font-size: 19px;
    text-align: center;
  }

  .shelter-text > span {
    text-align: center;
  }

  /* .cause-mobile {
    margin-top: 60px !important;
  } */
  .bigSc {
    display: none;
  }
  /* .page-title-area {

    background-image: url(img/mobi.png);
  } */

  .about-area,
  .about-style-three {
    /* margin-top: 50px; */
    align-items: center;
  }
  .pd-top{
    margin-top: 50px;
  }

  .volunteers-areaone {
    margin-top: 50px;
  }

  /* .page-title-area,
  .page-title-content {
    margin-top: 0px !important;
  } */

  /* .page-title-area,
  .page-title-content ul li a {
    font-size: 23px !important;
    background-image: none!important;
  } */
  .donation-bg {
    background-image: none !important;
  }

  /* .cause-mobile-fund {
    margin-top: -150px !important;
  } */

  .page-title-content ul li a {
    font-size: 18px !important;
  }

  .contact-info-area {
    margin-top: -100px !important;
  }

  .about-img-3,
  .mr-15 {
    margin-top: 70px !important;
    margin-bottom: 0px;
  }
  .marg {
    margin-top: 170px !important;
    margin-bottom: 0px;
  }

  /* .about-us-mobile {
    margin-top: -160px !important;
  } */

  .about-content > span {
    text-align: center !important;
  }

  .cause-text {
    bottom: 18px !important;
  }

  .cause-text h4 {
    display: none !important;
  }

  .cause-text p {
    display: none !important;
  }

  .cause-text span {
    display: none !important;
  }

  .title h2 {
    font-size: 22px;
  }

  .page-title-content h2 {
    font-size: 22px !important;
  }

  .form {
    width: 100% !important;
  }
  .single-contact-info {
    margin-top: 44px;
  }
  .subscribe-content h2 {
    margin-top: -50px;
  }
  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="date"],
  input[type="password"],
  input[type="tel"],
  select,
  textarea {
    padding: 0px;
    /* margin: 10px; */
  }
  .subscribe-area {
    padding-bottom: 0px;
  }
}

@media only screen and (min-width: 1200px) {
}

.owl-nav {
  display: none !important;
}

.owl-dots {
  display: none !important;
}

@media (min-width: 900px) {
  .cause-mobile {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .cause-system {
    display: none;
  }
  .obu-cases {
    display: none !important;
  }

  .mob-But {
   
    padding: 0px 5px 0px 5px !important;
    color: #eb8a20;
    transition: var(--transition);
    text-align: center;
    /* position: relative; */
    background-color: #59a5f100;  
    border: solid 2px #0286cd;
  }

 

  .navbar-light .navbar-nav .nav-link {
    background-color: #fff !important;
    z-index: -9999999 !important;
  }

  .navbar-collapse {
    margin-left: -12px;
    padding-left: 35px;
    text-align: center;
    align-items: center;
    background-color: #fff !important;
  }
  .donation-bg-volanteer {
    background-image: none;
  }
}

.text {
  margin: 0 60px;
}

@media screen and (max-width: 900px) {
  .cause-system {
    display: none;
  }
  .page-title-area .page-title-content {
    padding-top: 25px !important;
  }
  .btn.small {
    font-size: 13px;
    width: 105%;
    padding: 5px;
    border: 2px solid #ed15638a;
    color: #fff;
  }
  .banner-content h1 {
    font-size: 40px;
    padding: 0px 10px 0px 10px;
    color: var(--heading-color);
    margin-bottom: 25px;
  }
  .banner-content p {
    margin-bottom: 24px;
    font-size: 20px;
    padding: 0px 10px 0px 10px;
  }
  .single-footer-widget {
    text-align: center;
  }
  .single-footer-widget .import-link li a i {
    float: right;
    margin-right: 30px;
  }
}
.text {
  margin: 0 60px;
}
.twitter {
  color: #72898b;
  text-decoration: none;
  display: block;
  padding: 14px;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.twitter:hover {
  color: #ff7d6d;
  text-decoration: none;
}
/* span {
  font-style: italic;
  display: block;
  } */
img {
  max-width: 100%;
}
/* Floating Social Media Bar Style Starts Here */
.fl-fl {
  background: #fff0;
  text-transform: uppercase;
  letter-spacing: 3px;
  padding: 0px 4px 0px 0px;
  width: 185px;
  border-radius: 10px;
  position: fixed;
  right: -150px;
  height:35px;
  z-index: 1000;
  font: normal normal 10px Arial;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
/* .fa {
  font-size: 20px;
  color: #fff;
  padding: 10px 0;
  width: 40px;
  margin-left: 8px;
} */
.fl-fl:hover {
  right: 0;
}
.fl-fl a {
  color: #000 !important;
  text-decoration: none;
  padding-left: 4px;
  text-align: center;
  line-height: 43px !important;
  vertical-align: top !important;
}

.float-fb {
  top: 210px;
  background-color: #fff;
}

.float-tw {
  top: 260px;
  background-color: #fff;
}

.float-gp {
  top: 310px;
  background-color: #fff;
}

.float-rs {
  top: 360px;
  background-color: #fff;
}

.float-ig {
  top: 410px;
  background-color: #fff;
}

.float-pn {
  top: 460px;
  background-color: #fff;
}

/* #white-clr {
        color: #fff;
      } */

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #000;
  background-color: #efefef3d;
  font-weight: 700;
  border-radius: 0px 50px 50px 0px;
  box-shadow: 0px 2px #0286cd !important;
}

.nav-link {
  color: #000;
  font-weight: 700;
}

.height-img {
  height: 225px;
  width: 323px !important;
}

.page-title-area::before {
  background-color: #7974761c;
}

.page-title-area .page-title-content {
  /* background-color: #e1e1e12b; */
  /* margin-top: 50px !important; */
  border-radius: 15px 15px 0px 0px;
  /*margin-right: -128px;*/
  padding-top: 150px;
}

.about-content h2 {
  font-size: 20px;
  color: #000;
  /*margin-bottom: 30px;*/
}

.text {
  margin: 0 60px;
}

.service-serv {
  padding: 10px;
}

/* .cause-text {
  bottom: 160px;
} */

.nav-link {
  color: #0286cd;
  font-weight: 600;
}
.nav-link:focus,
.nav-link:hover {
  color: #0286cd;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  font-weight: 600;
}

.nav-link:focus,
.nav-link:hover {
  color: #0286cd;
}

.card-header {
  border-bottom: 1px solid rgb(255 255 255);
}

.section-title {
  margin: 10px auto 16px !important;
}

.cause-inner {
  border: 10px solid #0286cd5e;
}

/* element.style {
} */
/* .sidebar-widget {
  background: linear-gradient(to right, #0286CD82, #0286CDbd);
} */

.cause-bg:before {
  background: #2f2f2f52;
}

/* .sidebar-widget {
  background-image: url(.../img/education1.jpg) !important;
} */

.choose-us-from-bg .form-group .form-control {
  background-color: #461e46;
}

.form-select {
  background-color: #461e46;
}

.single-counter {
  height: 165px;
}

.box-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /*padding: 35px 15px;*/
  width: 100%;
}

.row {
  --bs-gutter-x: 0px !important;
}

@media screen and (min-width: 1380px) {
  .box-container {
    flex-direction: row;
  }
}

.box-item {
  position: relative;
  backface-visibility: hidden;
  width: 415px;
  margin-bottom: 35px;
  max-width: 100%;
  padding: 1px;
}

.flip-box {
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  perspective: 1000px;
  -webkit-perspective: 1000px;
}

.flip-box-front,
.flip-box-back {
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  min-height: 310px;
  -ms-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -webkit-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-front {
  -ms-transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-front {
  -ms-transform: rotateY(-180deg);
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.flip-box-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  -ms-transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-back {
  -ms-transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.flip-box .inner {
  position: absolute;
  left: 0;
  width: 100%;

  padding: 10px;
  outline: 1px solid transparent;
  -webkit-perspective: inherit;
  perspective: inherit;
  z-index: 2;

  transform: translateY(-50%) translateZ(60px) scale(0.94);
  -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
  -ms-transform: translateY(-50%) translateZ(60px) scale(0.94);
  top: 50%;
}

.flip-box-header {
  font-size: 22px;
  color: #000;
}

.flip-box p {
  font-size: 20px;
  line-height: 1.5em;
  color: #000;
}

.flip-box-img {
  margin-top: 25px;
}

.flip-box-button {
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  margin-top: 25px;
  padding: 15px 20px;
  text-transform: uppercase;
}

.form-select {
  color: #7b7b7b;
}

.form {
  background-color: #79747638;
  border-radius: 20px 0px 20px 0px;
  box-sizing: border-box;
  height: 465px;
  padding: 6px;
  width: 320px;
}

.title {
  color: #eee;
  font-family: sans-serif;
  font-size: 36px;
  font-weight: 600;
  margin-top: 30px;
}

.subtitle {
  color: #eee;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}

.input-container {
  height: 50px;
  position: relative;
  width: 97%;
}

.ic1 {
  margin-top: 20px;
}

.ic2 {
  margin-top: 30px;
}

.input {
  background-color: #eeeef1;
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: #000;
  font-size: 18px;
  height: 100%;
  outline: 0;
  padding: 4px 20px 0;
  width: 100%;
}

.cut {
  background-color: #79747605;
  border-radius: 10px;
  height: 20px;
  left: 20px;
  position: absolute;
  top: -20px;
  transform: translateY(0);
  transition: transform 200ms;
  width: 76px;
}

.cut-short {
  width: 50px;
}

.input:focus ~ .cut,
.input:not(:placeholder-shown) ~ .cut {
  transform: translateY(8px);
}

.placeholder {
  color: #65657b;
  font-family: sans-serif;
  left: 20px;
  line-height: 14px;
  pointer-events: none;
  position: absolute;
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms;
  top: 20px;
}

.input:focus ~ .placeholder,
.input:not(:placeholder-shown) ~ .placeholder {
  transform: translateY(-30px) translateX(10px) scale(0.75);
}

.input:not(:placeholder-shown) ~ .placeholder {
  color: #808097;
}

.input:focus ~ .placeholder {
  color: #000;
}

.submit {
  background-color: #ed15638a;

  border: 0;
  box-sizing: border-box;
  color: #eee;
  cursor: pointer;
  font-size: 18px;
  height: 50px;
  margin-top: 40px;
  outline: 0;
  text-align: center;
  width: 100%;
  border-radius: 20px 0px 20px 0px;
}

.submit:active {
  background-color: #06b;
}

.single-contact-info {
  margin-bottom: 30px;
  text-align: center;
  margin-right: 10px;
  border: 1px solid #645d5d08;
  padding: 40px;
  background-color: #7e76760a;
  border-radius: 30px 0px;
  box-shadow: 2px 2px 0 0px #8d7c7c54;
}

/* .page-title-area {
  padding-top: 0px;
  padding-bottom: 0;
  position: relative;
  z-index: 1;
  background-image: url(./assets/images/page-bg/contact-img.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
} */

.card-header {
  border-bottom: 1px solid rgb(255 255 255);
}

.section-title {
  margin: -7px auto 16px;
}

.cause-inner {
  border: 10px solid #0286cd70;
}

/* .page-title-area {

height: 380px !important;
} */
/* 
.page-title-area::before {

background-color: #7974761c;
} */

.page-title-area .page-title-content {
  /* margin-top: 60px !important; */
  border-radius: 15px 15px 0px 0px;
  margin-right: -4px;
}

.single-contact-info p {
  color: #000;
}

.single-contact-info .call-us {
  color: #000;
}

.main-contact-area h3 {
  text-align: center;
}

.page-title-area .page-title-content h2 {
  font-size: 30px !important;
  color: #fff;
}

/* .page-title-area .page-title-content ul li a {
  color: #ffffff;
  font-size: 20px !important;
} */

.view-form {
  /* background-color: #cfcfcf42; */
  /* box-shadow: 2px 2px #8d7c7c; */
}

.form-control {
  box-shadow: 2px 2px 0 0px #8d7c7c;
}

.fp {
  width: 95% !important;
}

.nav-pills-custom .nav-link {
  color: #000;
  font-weight: 600;
  margin-bottom: 25px !important;
  background: #f5f4f4;
  position: relative;
}

.nav-pills-custom .nav-link.active {
  color: #0286cd;
  font-weight: 600;
  background: #f0f0f073;
}

.shadow {
  box-shadow: 0 2px 1px #0286cd !important;
  border: 1px solid #e5e2e2 !important;
}

/* Add indicator arrow for the active tab */
@media (min-width: 992px) {
  .nav-pills-custom .nav-link::before {
    content: "";
    display: block;
    border-top: 8px solid transparent;
    border-left: 10px solid #0286cd;
    border-bottom: 8px solid transparent;
    position: absolute;
    top: 50%;
    right: -20px;
    transform: translateY(-50%);
    opacity: 0;
  }
  .page-title-area .page-title-content {
    height: 400px !important;
  }
}

.dropdown-menu.show {
  display: block;
  width: 327px !important;
  text-align: center !important;
  background: #e9e7e7;
}

.nav-pills-custom .nav-link.active::before {
  opacity: 1;
}

.navbar-light .navbar-nav .nav-link {
  color: rgb(0 0 0) !important;
  background-color: #efefef;
  font-size: 18px;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #f7941d !important;
}

.navbar {
  height: 100px !important;
}

.slick-current {
  display: none;
}

@media only screen and (max-width: 900px) {
  #mobi-img-hide {
    display: none !important;
  }
  .event-details-content .event-details-img .date {
    position: absolute;
    top: 0px;
    height: 77px;
    right: 0px;
    text-align: center;
    background-color: #0286cd;
    color: var(--white-color);
    padding: 5px 10px;
    /* font-size: 20px; */
    font-weight: 600;
  }
  .go-top.active {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
    right: 5%;
    top: 80%;
    transform: scale(1);
  }
}
.filter-btn {
  color: #000 !important;
  background-color: #fff !important;
  border-color: #0286cd !important;
  width: 100% !important;
}

/*  global classes */

.btn {
  text-transform: uppercase;
  background: transparent;
  color: var(--clr-black);
  padding: 0.375rem 0.75rem;
  letter-spacing: var(--spacing);
  display: inline-block;
  transition: var(--transition);
  font-size: 0.875rem;
  border: 2px solid var(--clr-black);
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: var(--radius);
}
.btn:hover {
  color: var(--clr-white);
  background: var(--clr-black);
}
/* section */
.section {
  padding: 5rem 0;
}

.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
}
@media screen and (min-width: 992px) {
  .section-center {
    width: 95vw;
  }
}
main {
  min-height: 100vh;
  display: grid;
  place-items: center;
}
/*
=============== 
About
===============
*/
.title {
  text-align: center;
  margin-bottom: 4rem;
}
.title p {
  width: 80%;
  margin: 0 auto;
}

.about-img {
  margin-bottom: 2rem;
}
.about-img img {
  border-radius: var(--radius);
  object-fit: cover;
  height: 20rem;
}


@media screen and (min-width: 992px) {
  .about-img {
    margin-bottom: 0;
  }

  .about-center {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
  }
}
.about {
  background: var(--clr-white);
  border-radius: var(--radius);
  display: grid;
  grid-template-rows: auto 1fr;
}
.btn-container {
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.tab-btn:nth-child(1) {
  border-top-left-radius: var(--radius);
}
.tab-btn:nth-child(3) {
  border-top-right-radius: var(--radius);
}
.tab-btn {
  padding: 1rem 0;
  border: none;
  text-transform: capitalize;
  font-size: 1rem;
  display: block;
  background: var(--clr-grey-9);
  cursor: pointer;
  transition: var(--transition);
  letter-spacing: var(--spacing);
}
.tab-btn:hover:not(.active) {
  background: var(--clr-primary-10);
  color: var(--clr-primary-5);
}
.about-content {
  border-bottom-left-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
  padding: 2rem 1.5rem;
}
/* hide content */
.content {
  display: none;
}
.tab-btn.active {
  background-color: #0286cd;
}
.content.active {
  display: block;
}

/* statu bar */
.progress-bar__container {
  width: 91%;
  height: 0.4rem;
  border-radius: 2rem;
  position: relative;
  overflow: hidden;
  transition: all 0.5s;
  will-change: transform;
  box-shadow: 0 0 2px #0286cd;
}

.progress-bar {
  position: absolute;
  height: 3%;
  /* height:88%; */
  /* width: 2%!important; */

  content: "";
  background-color: #0286cd;
  /* top:0;
  bottom: 0;
  left: -100%; */
  border-radius: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: sans-serif;
}

.progress-bar__text {
  display: none;
}

/* form */

/* Created */
.create {
  width: 30%;
  max-width: 785px;
  min-width: 320px;
  border-radius: 15px;
  background-color: #131b2b;
  padding: 3rem;
}

/* Content */
.content {
  width: 100%;
  margin: auto;
}

.create h1 {
  margin-top: 0;
}

.content div p {
  margin: 0;
  font-weight: bold;
  font-size: 10px;
}

/* form */
.form {
  margin-top: 2rem;
}

.form label {
  font-weight: bold;
  display: block;
  font-size: 13px;
  margin-bottom: 0.3rem;
}

.form input:not([type="submit"]) {
  display: block;
  border: 1px solid #8d9299;
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 0.8rem;
  width: 100%;
  padding: 0.5rem;
  /* color: white; */
  /* cursor: */
}
/* 
.form input[type="submit"] {
  padding: 1rem;
  width: 60%;
  border-radius: 10px;
  border: 1px solid #1676aa;
  background-color: #f7b25e;
  font-weight: bold;
  cursor: pointer;
  color: #000;
  margin-top: 1.1rem;
  margin-left: 3.5rem;
 
} */

.modal-header {
  border-bottom: 1px solid #fff;
}

.cont {
  background-color: #f2f2f2;
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="date"],
input[type="password"],
input[type="tel"],
select,
textarea {
  padding: 10px;

  /* margin: 10px; */
}

fieldset {
  border-radius: 10px;

  margin: 10px;
}

input[type="submit"] {
  font-size: 15px;
  color: #000;

  font-weight: 600;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  text-align: center;
  position: relative;
  /* z-index: 1; */
  overflow: hidden;
  display: inline-table;
  padding: 09px 28px;
  background-color: #59a5f100;
  /* background-color: #0286CD; */
  border: solid 2px #0286cd;
  
}

input[type="submit"]:hover {
  background-color: #0286cd;
  color: #fff;
border: 2px solid #f7b25e;
}

/* social media */
.share_content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.share_button {
  position: fixed;
  bottom: 25%;
  width: 50px;
  left: 0px;
  height: 50px;
  border-radius: 50%;
  background: #0286cd;
  display: flex;
  justify-content: center;
  z-index: 9999999;
  align-items: center;
  box-shadow: 0px 1px 5px #39c18d;
  cursor: pointer;
  margin-right: 10px;
}

.active_button i {
  color: #49e2a7 !important;
}

.share_button i {
  font-size: 18px;
  color: #b1c8c9;
  transition: color 0.4s ease-in-out;
}

.share_icons {
  position: fixed;
  width: auto;
  height: 0px;
  left: 55px;
  bottom: 30%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 5px #0286cd;
  border-radius: 25px;
  padding: 0px 10px;
  transition: width 0.4s, height 0.4s ease-in-out;
  visibility: hidden;
}

.active_content {
  width: auto;
  left: 55px;
  bottom: 30%;
  position: fixed;
  height: 50px;
  z-index: 999999998;
  visibility: visible;
}

.icon_button {
  display: inline-block;
  padding: 0 10px;
  font-size: 25px;
  text-decoration: none;
  color: #000;
}

/* .more_share
{
  position: relative;
  right: -60px;
  background-color: #000;
  width: 50px;
  z-index: 99999999999;
  height: 50px;
  border-radius: 50%;
  
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 5px #0286CD;
  cursor: pointer;
} */

.more_share i {
  font-size: 20px;
  color: #b1c8c9;
  transition: transform 0.3s ease-in-out;
}

/* .more_content
{
    position: absolute;
    height: 0px;
    height: 0px;
    background: #ffffff;
    display: none;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    box-shadow: 0px 1px 5px #39c18d;
    border-radius: 25px;
    padding: 10px;
    transition: width .5s,height .5s ease-in-out;
    top: 60px;
    left: 0px;
} */
/* 
.active_more_content
{
    height: auto;
    width: auto;
    display:flex;
} */

/* .active_more_button i
{
    color: #49e2a7!important;
    transform: rotate(180deg);
} */

.more_content a {
  padding: 10px !important;
}

.tab-content {
  padding-top: 30px;
  padding-bottom: 30px;
}

.tab-title {
  font-size: 20px;
}

.fa-facebook-f:hover {
  color: #4267b2;
}

.fa-twitter:hover {
  color: #38a1f3;
}

.fa-youtube:hover {
  color: #ed3833;
}

.fa-facebook-messenger:hover {
  color: #0084ff;
}
/* Donate Now Start */

/*form styles*/
#msform {
  text-align: center;
  position: relative;
  margin-top: 30px;
}

#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 0px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
  padding: 20px 30px;
  box-sizing: border-box;
  width: 80%;
  margin: 0 10%;

  /*stacking fieldsets above each other*/
  position: relative;
}

/*Hide all except first fieldset*/
#msform fieldset:not(:first-of-type) {
  display: none;
}

/*inputs*/
#msform input,
#msform textarea {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 0px;
  margin-bottom: 10px;
  width: 100%;
  box-sizing: border-box;
  font-family: montserrat;
  color: #2c3e50;
  font-size: 13px;
}

#msform input:focus,
#msform textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #ee0979;
  outline-width: 0;
  transition: All 0.5s ease-in;
  -webkit-transition: All 0.5s ease-in;
  -moz-transition: All 0.5s ease-in;
  -o-transition: All 0.5s ease-in;
}

/*buttons*/
#msform .action-button {
  width: 100px;
  background: #ee0979;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 25px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
}

#msform .action-button:hover,
#msform .action-button:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px #ee0979;
}

#msform .action-button-previous {
  width: 100px;
  background: #c5c5f1;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 25px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
}

#msform .action-button-previous:hover,
#msform .action-button-previous:focus {
  box-shadow: 0 0 0 2px white, 0 0 0 3px #c5c5f1;
}

/*headings*/
.fs-title {
  font-size: 18px;
  text-transform: uppercase;
  color: #2c3e50;
  margin-bottom: 10px;
  letter-spacing: 2px;
  font-weight: bold;
}

.fs-subtitle {
  font-weight: normal;
  font-size: 13px;
  color: #666;
  margin-bottom: 20px;
}

/*progressbar*/
#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  /*CSS counters to number the steps*/
  counter-reset: step;
}

#progressbar li {
  list-style-type: none;
  color: white;
  text-transform: uppercase;
  font-size: 9px;
  width: 33.33%;
  float: left;
  position: relative;
  letter-spacing: 1px;
}

#progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 24px;
  height: 24px;
  line-height: 26px;
  display: block;
  font-size: 12px;
  color: #333;
  background: white;
  border-radius: 25px;
  margin: 0 auto 10px auto;
}

/*progressbar connectors*/
#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: white;
  position: absolute;
  left: -50%;
  top: 9px;
  z-index: -1; /*put it behind the numbers*/
}

#progressbar li:first-child:after {
  /*connector not needed before the first step*/
  content: none;
}

/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li.active:before,
#progressbar li.active:after {
  background: #ee0979;
  color: white;
}

/* Not relevant to this form */
.dme_link {
  margin-top: 30px;
  text-align: center;
}
.dme_link a {
  background: #fff;
  font-weight: bold;
  color: #ee0979;
  border: 0 none;
  border-radius: 25px;
  cursor: pointer;
  padding: 5px 25px;
  font-size: 12px;
}

.dme_link a:hover,
.dme_link a:focus {
  background: #c5c5f1;
  text-decoration: none;
}

/* Donate Now End */

#toast {
  visibility: hidden;
  min-width: 250px;
  margin-left: -200px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: black;
  text-align: center;
  border-radius: 10px;
  padding: 16px;
  position: fixed;
  /* z-index: 1; */
  left: 50%;
  top: 50%;
  font-size: 16px;
  z-index: 9999;
}

#toast.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 50%;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 50%;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    top: 50%;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    top: 50%;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}
.checkicon i {
  font-size: 40px;
  color: #47d764;
}
.donatepg {
  text-align: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 80px;
}
.donate-form {
  padding: 50px 20px 50px 20px !important;
  margin-right: 20px;
  border: 2px solid #efefef;
  box-shadow: 3px 5px #888888;
  background-color: #dbeaf3;
}
.form-donate {
  padding: 10px 20px 10px 20px !important;
  right: 20px !important;
  margin: 10px;
}

.donate-bnt {
  padding: 1rem;
  width: 60%;
  border-radius: 10px;
  /* border: 1px solid #8E284E; */
  background-color: #0286cd;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  margin-top: 1.1rem;
  /* margin-left: 3.5rem; */
}
.containers .card{
  width: 200px;
  margin: 40px 20px;
  border-radius: 4px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.5);
  cursor: pointer;
  transform-style: preserve-3d;
  transition: background-color 0.5s ease, transform 0.5s ease;
  padding: 10px;
}
.containers .card img{
  width: 100%;
  height: 100%;
}
.containers:hover .card,
.containers:hover .card,
.containers:hover .card{
  transform: perspective(500px) rotatey(30deg);  
  filter: grayscale(100%);
}
.containers .card:hover,
.containers .card:focus,
.containers .card:active{
  transform: perspective(500px) rotatey(0); 
  filter: grayscale(0%);
}

.containers .card:hover ~ .card,
.containers .card:focus ~ .card,
.containers .card:active ~ .card{
  transform: perspective(500px) rotatey(-30deg);  
  filter: grayscale(100%);
}
.containers .card:hover img{
  filter: grayscale(0%) !important;
}

.custom-btn {
  width: 130px;
  height: 40px;
  color: #fff;
  border-radius: 5px;
  padding: 10px 25px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
   box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  outline: none;
}







#gallery {
  padding-top: 40px;
  @media screen and (min-width: 991px) {
    padding: 60px 30px 0 30px;
  }
}

.img-wrapper {
  position: relative;
  /* margin-top: 1px;
 padding: 1px; */
 
}
.img-wrapper img {
  width: 100%;
  height: 400px;
  padding: 3px;
}
.img-overlay {
  background: rgb(0 0 0 / 50%);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
 
}
/* .img-overlay i {
  color: #fff;
  font-size: 3em;
} */

#overlay {
  background:rgb(0 0 0 / 81%);
  width: 100%;
  height: 800px;
  position: fixed;
  top: 12%;
  /* bottom: 50%; */
  left: 00%;
  /* right: 50%; */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  /* // Removes blue highlight */
  -webkit-user-select: none;
  -moz-user-select: none;    
  -ms-user-select: none; 
  user-select: none; 

}
#overlay img {
  margin: 0;
  width: 1200px;
  height: 800px;
  object-fit: contain;
  padding: 3%;

}
#nextButton {
  color: #fff;
  font-size: 2em;
  transition: opacity 0.8s;
 

}
#nextButton:hover {
  opacity: 0.7;
}

#prevButton {
  color: #fff;
  font-size: 2em;
  transition: opacity 0.8s;

  
}
#prevButton:hover {
  opacity: 0.7;
}
#exitButton {
  color: #fff;
  font-size: 2em;
  transition: opacity 0.8s;
  position: absolute;
  top: 15px;
  right: 15px;
 
  
}
#exitButton:hover {
  opacity: 0.7;
}






/* Sign Up form */

.wrapper {
  margin: 0 auto;
  width: 400px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-top: 100px;
    margin-bottom: 50px;
}

.section {
  padding: 1rem;
}

.header {
  position: relative;
  /* text-align: center; */
}
.header__text {
  position: relative;
  padding: 3.5rem 0 7rem;
}
.header__text > h1 {
  margin: 0;
  font-size: 2.5rem;
}
.header > .trapezoid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  transform: skewY(-10deg);
  transform-origin: top left;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background: linear-gradient(#1676aa,#7eb3d0), url("https://images.pexels.com/photos/520936/pexels-photo-520936.jpeg?w=940&h=650&auto=compress&cs=tinysrgb");
  background-position: top center;
  background-attachment: fixed;
}

 /* form {
  margin: 0 auto;
  max-width: 17rem;
  overflow: auto;
} 
 form > * + * {
  margin-top: 1rem;
}  */
.signUp > input {
  border: 0;
  border-bottom: 1px solid #000;
  border-radius: 0;
  width: 100%;
  height: 3rem;
  padding: 0 0 0.25rem 0;
  font-size: 1rem;
  color: #000;
  background: #fefefe;
}
.signUp> input:focus {
  outline: none;
}
.signUp> input::placeholder {
  color: #1476aa;
}

.signUp > button {
  margin-top: 2rem;
  border: 0;
  border-radius: 200px;
  width: 100%;
  padding: 0.85rem;
  font-size: 1rem;
  color: #fefefe;
  background: #1677ab;
} 
.signUp > button:focus {
  outline: none;
}
.signUp > p {
  margin: 0.25rem 0 0;
  text-align: center;
  color: #1476aa;
} 

.sign-up {
  display: none;
}

.opposite-btn1,
.opposite-btn2 {
  cursor: pointer;
}


.signIn > input {
  border: 0;
  border-bottom: 1px solid #000;
  border-radius: 0;
  width: 100%;
  height: 3rem;
  padding: 0 0 0.25rem 0;
  font-size: 1rem;
  color: #000;
  background: #fefefe;
}
.signIn> input:focus {
  outline: none;
}
.signIn> input::placeholder {
  color: #1476aa;
}
.signIn > button {
  margin-top: 2rem;
  border: 0;
  border-radius: 200px;
  width: 100%;
  padding: 0.85rem;
  font-size: 1rem;
  color: #fefefe;
  background: #1776a9;
} 
.signIn > button:focus {
  outline: none;
}
.signIn > p {
  margin: 0.25rem 0 0;
  text-align: center;
  color: #0286cd;
} 

.formMargin{
  padding-left: 8px;
}